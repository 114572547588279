import {PersonaOptions} from '@nlux/react';

export const personas: PersonaOptions = {
  bot: {
    name: 'OptiBot',
    picture: '/chat1.svg',
    tagline: 'OptiWisdom Inc.',
  },
  user: {
    name: 'User',
    picture: 'userchat.svg'
  }
};