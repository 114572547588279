import React from 'react';
import { Image } from 'react-native';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import RFMQuartileSupplier from '../../charts/rfm_quartile_supplier';
import Top20SupplierAmount from '../../charts/top20_supplier_amount';
import Top20SupplierCount from '../../charts/top20_supplier_count';
import SupplierSegmentation3D from '../../charts/3d_supplier_segmentation';

import SeasonalityImg from "../../assets/img/season.png";

export default function SupplierAnalytics(packageType, msg="") {

  const { t, i18n } = useTranslation();

  return (   
    <> 

      { packageType === "premium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
                {t("Supplier Segmentation Analysis")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <RFMQuartileSupplier hvalue="250%"/>
          </Card>
        </div> 
      }

      {packageType === "freemium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
              {t("Supplier Segmentation Analysis")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>

            <div className="blur-container"> 
              <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
              <a type='button' className="btn" href='/packages'>{t(msg)}</a>
            </div>

          </Card>
        </div>
      
      }
      
      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Top 20 Suppliers (Amount)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <Top20SupplierAmount hvalue="150%"/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Top 20 Suppliers (Count)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <Top20SupplierCount hvalue="150%"/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("3D Supplier Segmentation Analysis")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <SupplierSegmentation3D hvalue="150%"/>
        </Card>
      </div>

    </>
  );
}
