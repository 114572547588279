import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import { useTheme } from '@material-ui/core/styles';

import { addThousandSeperator } from "../components/ThousandSep";

export default function ProfitImportanceMonthly({ hvalue="100%" }) {

  const [labels, setLabel] = useState(null);
  const [val, setVal] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;

  useEffect(() => {

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/profit/"
      }
    }
  
    fetch(process.env.REACT_APP_REQ_IP + '/profit_importance_monthly', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.results) {
        const label = JSON.parse(data.label);
        const values = JSON.parse(data['1chart']);
  
        setLabel(label);
        setVal(values);
      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
        console.log(err)
    });

  }, [firmid, integration]);


  return (
    <>
      {labels === null && val === null && serverMsg !== null && 
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>{t(serverMsg)}</span>
        </div>
      }
      {labels !== null && val !== null && serverMsg === null &&
      <Chart
          type="bar"
          height={hvalue}
          options={
            {
              chart: {
                animations: {
                  enabled: false
                },
                stacked: false,
                zoom: {
                  autoScaleYaxis: true,
                  autoScaleXaxis: true
                },
                parentHeightOffset: 0,
                background: themeBack
              },
              theme: {
                mode: themeMode, 
              },  
              plotOptions: {
                bar: {
                  barHeight: '100%',
                  distributed: true,
                  horizontal: true,
                  dataLabels: {
                    position: 'bottom'
                  },
                }
              },        
              colors: ['#0064ff', '#67a3ff', 
              '#d83534', '#33cd32', 
              '#ff7e0e', '#003280',
              '#294166', '#6c1b1a',
              '#249023', '#70dc70',
              '#cce0ff', '#d6f5d6',
              '#ffe5cf', '#0f3d0f',
              '#b3580a', '#e05d5d',
              '#707070', '#FF6262'
              ],
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#fff']
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + addThousandSeperator(val, 4);
              },
              offsetX: 0,
              dropShadow: {
                enabled: true
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
            xaxis: {
              categories: labels,
            },
            yaxis: {
              labels: {
                show: false
              }
            },
            tooltip: {
              theme: themeMode,
              x: {
                show: false
              },
              y: {
                title: {
                  show: false
                }
              }
            }
            }
          }
          series={[
            {
              data: val,
              name: t("Value")
            }
          ]}
        />
      }
    </>

  );
}