import React from 'react';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import Top20SalespersonAmount from '../../charts/top20_salesperson_amount';
import Top20SalespersonCount from '../../charts/top20_salesperson_count';

export default function SalespersonAnalysis() {

  const { t, i18n } = useTranslation();

  return (   
    <> 
      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Top 20 Salesperson (Amount)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <Top20SalespersonAmount hvalue="150%"/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Top 20 Salesperson (Amount)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <Top20SalespersonCount hvalue="150%"/>
        </Card>
      </div>

    </>
  );
}
