import React, { useState, useEffect } from 'react';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

export default function ProfitPredAmountEndofYear() {

  const [data, setValue] = useState(null);
  const [serverMsg, setServermsg] = useState(null);

  const firmid = window.sessionStorage.getItem("comp_id");

  const { t, i18n } = useTranslation();

  useEffect(() => {

    const integration = window.sessionStorage.getItem("integration");

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/profit/"
      }
    }


    fetch(process.env.REACT_APP_REQ_IP + '/profit_prediction_amount_endofyear', { 
            method: 'POST', 
            mode: 'cors' ,
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify(list_obj)}
        ).then(res => res.json()).then(data => {

          if(data.results) {
            var pred = data.veri.toLocaleString(undefined, {maximumFractionDigits:2})
            setValue(pred); 
          }
          else {
            setServermsg(data.message);
          }

    })
    .catch(err => {
        console.log(err)
    });

  }, [firmid]);

  if(serverMsg) {
    return (
      <div className="blur-container"> 
        <Image source={SeasonalityImg}  style={{ height:100, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
        <span>{t(serverMsg)}</span>
      </div>
    );
  }
  else {
    return (
      <div className='d-flex justify-content-start h-100 align-items-center'>
        <CallMissedOutgoingIcon className='bar-m-icon'/>
        <div className='bar-value'>{data}</div>
      </div>
    );
  }
}
