import {useMemo} from 'react';
import {AiChat} from '@nlux/react';
import '@nlux/themes/nova.css';
import {streamAdapter} from '../components/adapter.ts'
import {personas} from '../components/personas.tsx';

function ChatBot() {

const adapter = useMemo(() => streamAdapter, []);

return (
    <AiChat
        adapter={adapter}
        personaOptions={personas}
        layoutOptions={{
            height: '80vh',
            maxWidth: 1000,
        }}
        promptBoxOptions= {{
            autoFocus: false,
            placeholder: "Sorunuzu yazabilirsiniz..."
            
        }}
    />
)
}


export default ChatBot;
