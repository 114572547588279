import {React} from 'react';
import { Image } from 'react-native';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import CustCards from '../../charts/customer_cards';

import SeasonalityImg from "../../assets/img/season.png";

export default function CustomerCards(packageType, msg="") {
  const { t, i18n } = useTranslation();

  return (   
    <> 
    {packageType === "premium" &&
      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Customer Cards")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <CustCards hvalue="150%"/>
        </Card>
      </div>
    }

      {packageType === "freemium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
              {t("Customer Cards")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>

            <div className="blur-container"> 
              <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
              <a type='button' className="btn" href='/packages'>{t(msg)}</a>
            </div>

          </Card>
        </div>
      }
    </>
  );
}
