import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { handleLogout } from '../components/Logout.js';
import { useTranslation } from "react-i18next";

const Unauthorized = () => {

  let navigate = useNavigate();

  const { t, i18n } = useTranslation();


  useEffect(() => {

    handleLogout();
    navigate("/login", { replace: true });

  }, []);

  return (
    <article style={{ padding: "100px" }}>
      <h1>Oops!</h1>
      <p>{t("Sorry you don't have permission to access to this page")}</p>
    </article>
  )
}

export default Unauthorized;
