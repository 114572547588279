import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useTheme } from '@material-ui/core/styles';

import { addThousandSeperator } from "../components/ThousandSep";

export default function Top20ExpendAmount({ hvalue="100%" }) {

  const [val, setVal] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  const [dateRange, setDateRange] = useState("Tum");
  const [dateArray, setDateArray] = useState();
  const [chartData, setChartData] = useState(null);

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;

  const dateRangeChange = event => {

    const value  = event.target.value;
    setDateRange(value);
  };

  useEffect(() => {

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gider/"
      }
    }
  
    fetch(process.env.REACT_APP_REQ_IP + '/top20_expen_amount', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {

      if(data.results) {
        const values = JSON.parse(data['1chart']);
        let dates = JSON.parse(data['2chart']);

        dates = dates.filter((x, i, a) => a.indexOf(x) === i).toString().split(',');
        setDateArray(dates);

        let obj = {};
        values.forEach(({ Description, Tarih, amount }) => {
          if (Tarih in obj) {
            obj[Tarih].push({"x": Description, "y": amount});
          } else {
            obj[Tarih] = [{"x": Description, "y": amount}];
          }
        });

        setChartData(obj);
        setVal(values);
      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
        console.log(err)
    });

  }, [firmid, integration]);


  return (
    <>
      {val === null && serverMsg !== null && 
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>{t(serverMsg)}</span>
        </div>
      }
      {val !== null && dateArray !== null && dateRange !== null && serverMsg === null &&
        <div>
          <FormControl variant="outlined" className='w-25 my-4' required>
            <InputLabel id="select-ids">{t("Choose the Date")}</InputLabel>
            <Select
            labelId="select-ids"
            id="select_date"
            value={dateRange}
            onChange={dateRangeChange}
            label={t("Choose the Date")}>
                {dateArray.map((i) => (
                  <MenuItem key={i} value={i}>{t(i)}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <Chart
            type="bar"
            height={hvalue}
            options={
            {
            chart: {
              animations: {
                enabled: false
              },
              stacked: false,
              parentHeightOffset: 0,
              background: themeBack
            },
            theme: {
              mode: themeMode, 
            },   
            dataLabels: {
              enabled: false
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                // columnWidth: '50%',
              }
            },
            stroke: {
              width: 2,
              colors: ['#0064ff']
            },
            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            fill: {
              type: 'gradient',
              colors: ['#67a3ff'],
              gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100]
              },
            },
            xaxis: {
              // type: "category",
              labels: {
                show: true,
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
                trim: true
              },
              tickPlacement: "on"
            },
            yaxis: {
              labels: {
                formatter: function(value) {
                  if(value !== null){
                    return addThousandSeperator(value);
                  }
                }
              }
            },
            tooltip: {
              theme: themeMode,
              x: {
                show: true
              },
              y: {
                title: {
                  formatter: function () {
                    return t('Total Amount') + ":"
                  }
                }
              }
            }
            }
          }
          series={[{data : chartData[dateRange]}]}
        />
        </div>
      }
    </>

  );
}