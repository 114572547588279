import React, { useState, useEffect} from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";

import TopBar from "./components/TopBar";
import Widget from "./components/Widget";

import SalesSeasonalityTrendAnomaly from "./charts/sales_seasonality_trend_anomaly";
import SalesPredAmountWeekly from "./charts/sales_prediction_amount_weekly";
import SalesPredAmountMonthly from "./charts/sales_prediction_amount_monthly";
import SalesPredCountMonthly from "./charts/sales_prediction_count_monthly";
import SalesPredCountWeekly from "./charts/sales_prediction_count_weekly";
import SalesPredAmountEndofYear from "./charts/sales_prediction_amount_endofyear";
import SalesPredAmountNext12M from "./charts/sales_prediction_amount_next12m";
import SalesPredCountNext12M from "./charts/sales_prediction_count_next12m";
import SalesPredCountEndofYear from "./charts/sales_prediction_count_endofyear";
import SalesImportanceMonthly from "./charts/sales_importance_monthly";

import PurchaseSeasonalityTrendAnomaly from "./charts/purchase_seasonality_trend_anomaly";
import PurchasePredAmountWeekly from "./charts/purchase_prediction_amount_weekly";
import PurchasePredAmountMonthly from "./charts/purchase_prediction_amount_monthly";
import PurchasePredCountMonthly from "./charts/purchase_prediction_count_monthly";
import PurchasePredCountWeekly from "./charts/purchase_prediction_count_weekly";
import PurchasePredAmountEndofYear from "./charts/purchase_prediction_amount_endofyear";
import PurchasePredAmountNext12M from "./charts/purchase_prediction_amount_next12m";
import PurchasePredCountNext12M from "./charts/purchase_prediction_count_next12m";
import PurchasePredCountEndofYear from "./charts/purchase_prediction_count_endofyear";
import PurchaseImportanceMonthly from "./charts/purchase_importance_monthly";

import ProfitSeasonalityTrendAnomaly from "./charts/profit_seasonality_trend_anomaly";
import ProfitPredAmountWeekly from "./charts/profit_prediction_amount_weekly";
import ProfitPredAmountMonthly from "./charts/profit_prediction_amount_monthly";
import ProfitPredCountMonthly from "./charts/profit_prediction_count_monthly";
import ProfitPredCountWeekly from "./charts/profit_prediction_count_weekly";
import ProfitPredAmountEndofYear from "./charts/profit_prediction_amount_endofyear";
import ProfitPredAmountNext12M from "./charts/profit_prediction_amount_next12m";
import ProfitPredCountNext12M from "./charts/profit_prediction_count_next12m";
import ProfitPredCountEndofYear from "./charts/profit_prediction_count_endofyear";
import ProfitImportanceMonthly from "./charts/profit_importance_monthly";

import RFMQuartileCustomer from "./charts/rfm_quartile_customer";
import RFMQuartileExpend from "./charts/rfm_quartile_expend";
import RFMQuartileProduct from "./charts/rfm_quartile_product";
import RFMQuartileSupplier from "./charts/rfm_quartile_supplier";

import InitialLayout from "./Layout";

const originals = ["1", "2", "3", '4', '5', '6', "7", "8", "9", "10", "11", "12", "13", '14', '15', '16', "17", "18", "19", "20", "21", "22", "23", '24', '25', '26', "27", "28", "29", "30"];
const originalsPremium = ["1", "2", "3", '4', '5', '6', "7", "8", "9", "10", "11", "12", "13", '14', '15', '16', "17", "18", "19", "20", "21", "22", "23", '24', '25', '26', "27", "28", "29", "30", "31", "32", "33", "34"];

const def_orig = ["1", "2", "3", '4', '5', '6', "7", "8"];

const componentList = {
  "1": SalesPredAmountEndofYear,
  "2": SalesSeasonalityTrendAnomaly,
  "3": SalesPredAmountMonthly,
  "4": SalesPredAmountWeekly,
  "5": SalesPredCountMonthly,
  "6": SalesPredCountWeekly,
  "7": SalesPredAmountNext12M,
  "8": SalesPredCountNext12M,
  "9": SalesPredCountEndofYear,
  "10": SalesImportanceMonthly,
  "11": PurchasePredAmountEndofYear,
  "12": PurchaseSeasonalityTrendAnomaly,
  "13": PurchasePredAmountMonthly,
  "14": PurchasePredAmountWeekly,
  "15": PurchasePredCountMonthly,
  "16": PurchasePredCountWeekly,
  "17": PurchasePredAmountNext12M,
  "18": PurchasePredCountNext12M,
  "19": PurchasePredCountEndofYear,
  "20": PurchaseImportanceMonthly,
  "21": ProfitPredAmountEndofYear,
  "22": ProfitSeasonalityTrendAnomaly,
  "23": ProfitPredAmountMonthly,
  "24": ProfitPredAmountWeekly,
  "25": ProfitPredCountMonthly,
  "26": ProfitPredCountWeekly,
  "27": ProfitPredAmountNext12M,
  "28": ProfitPredCountNext12M,
  "29": ProfitPredCountEndofYear,
  "30": ProfitImportanceMonthly,
  "31": RFMQuartileExpend,
  "32": RFMQuartileCustomer,
  "33": RFMQuartileProduct,
  "34": RFMQuartileSupplier,
};

function Content({ packageType, size: { width }  })  {

  const [originalItems, setOrig] = useState(originals);
  const [items, setItems] = useState(def_orig);
  const [openMode, setOpenMode] = useState(true);

  useEffect(() => {

    if(packageType === "freemium") {
      setOrig(originals);
    }
    else {
      setOrig(originalsPremium)
    }

  }, [packageType]);

  const [layouts, setLayouts] = useState(
    getFromLS("layouts") || InitialLayout
  );

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = () => {
    saveToLS("layouts", layouts);
  };

  const onRemoveItem = (itemId) => {
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  const toogleOpenMode = () => {
    setOpenMode(!openMode);
  }

  return (
    <>
      <TopBar
        onLayoutSave={onLayoutSave}
        items={items}
        onRemoveItem={onRemoveItem}
        onAddItem={onAddItem}
        originalItems={originalItems}
        toogleOpenMode={toogleOpenMode}
        openMode={openMode}
      />
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width}
        isDraggable={!openMode}
        onLayoutChange={onLayoutChange}
      >
        {items.map((key) => (
          <div
            key={key}
            className="widget"
            data-grid={{ w: 3, h: 2, x: 0, y: Infinity }}>
            <Widget
              id={key}
              onRemoveItem={onRemoveItem}
              userInteractType={!openMode}
              component={componentList[key]}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(Content);

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {}
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}
