import React from 'react';

import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../assets/svg/success.svg";

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { handleLogout } from '../components/Logout.js';
import { useTranslation } from "react-i18next";

function Success() {

    // //routing components
    let navigate = useNavigate();

    const { t, i18n } = useTranslation();

    //if user not authenticated
    if(window.sessionStorage.getItem("uid") === null) {
        handleLogout();
        navigate("/login", { replace: true });
    }

    function handleBack(){
        navigate("/", { replace: true });
    }

    return (
        <article style={{ padding: "100px" }}>
            <h1 className='text-center'>{t("Ödeme Başarılı")} 🎉</h1>
            <h3 className="text-center">{t("Your payment has been successfully received and your package has been upgraded.")}</h3>
            <h3 className="text-center">{t("If you have remaining days from your old package, it has been added to your new expiration date.")}</h3>
            <div className="card-body">
                <SuccessIcon className="payment-icon" />
            </div>
            <button className="btn payment-btn"  onClick={handleBack} type='button' rel="nofollow">{t("Back to Home")}
                <DoubleArrowIcon className='ml-2'/>
            </button>
        </article>
    );

}

export default Success;