import React, { useState, useEffect } from 'react';
import ReactEcharts from "echarts-for-react"; 


import * as echarts from 'echarts/core';
import { TooltipComponent, VisualMapComponent } from 'echarts/components';

import { Scatter3DChart } from 'echarts-gl/charts';
import { Grid3DComponent } from 'echarts-gl/components';


import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

export default function CustomerSegmentation3D({ hvalue="100%" }) {

  const [val, setVal] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  echarts.use([
    TooltipComponent,
    VisualMapComponent,
    Grid3DComponent,
    Scatter3DChart,
  ]);
  
  useEffect(() => {

    // const ac = new AbortController();

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gelir/"
      }
    }

    fetch(process.env.REACT_APP_REQ_IP + '/rfm_quartile_customer', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.results) {

        const values = JSON.parse(data.df);
        
        const option = {
          grid3D: {
            axisLine: {
              show: true,
              lineStyle: {
                color: '#7a7a7a',
                width: 0.5
              }
            },
            axisPointer: {
              lineStyle: {
                color: '#7a7a7a'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: '#7a7a7a',
                opacity: 0.2 
              }
            },
            viewControl: {
              autoRotate: true,
            },
            // splitArea: {
            //   show: false,
            //   areaStyle: {
            //     color: ['#ffffff', '#f7f7f7']
            //   }
            // },

          },
          tooltip: {
            show:true,
            trigger: 'item',
            showContent: true,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            formatter: function (params) {
              return t("Name : ") + params.data.CustomerID + '<br/>' +
              'Frequency : ' + params.data.frequency + '<br/>' +
              'Recency : ' + params.data.recency + '<br/>' +
               'Monetary Value : ' + params.data.monetary_value + '<br/>' +
               'RFM Class : ' + params.data.RFMClass
            }
          },

          visualMap: [
            {
              left: 0,
              top: "20",
              calculable: true,
              dimension: 1,
              max: 10,
              inRange: {
                color: [
                  '#0064ff',
                  '#d83534'
                ]
              },
              textStyle: {
                color: "#7a7a7a"
              }
            },
          ],
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: true },
              saveAsImage: { show: true }
            }
          },
          xAxis3D: {
            name: "Güncellik",
            type: 'value',
            nameTextStyle: {
              color: "#7a7a7a"
            }
          },
          yAxis3D: {
            name: "Frekans",
            type: 'value',
            nameTextStyle: {
              color: "#7a7a7a"
            }
          },
          zAxis3D: {
            name: "Hacim",
            type: 'value',
            nameTextStyle: {
              color: "#7a7a7a"
            }
          },
          dataset: {
            dimensions: [
              'recency',
              'frequency',
              'monetary_value',
            ],
            source: values
          },
          series: [
            {
              name: "3D Analysis",
              type: 'scatter3D',
              symbolSize: 16,
              itemStyle: {
                borderWidth: 0,
                color: function(param) {
                  if (param.value.Segment === "Risk") return '#9a9a9a';
                  if (param.value.Segment === "Hibernate") return '#aeaeae';
                  if (param.value.Segment === "Cant Lose") return '#c2c2c2';
                  if (param.value.Segment === "Sleeper") return '#cdcdcd';
                  if (param.value.Segment === "Need Attention") return '#e1e1e1';
                  if (param.value.Segment === "Loyal") return '#f7d7d6';
                  if (param.value.Segment === "Promising") return '#efaeae';
                  if (param.value.Segment === "New Customer") return '#e88685';
                  if (param.value.Segment === "High Potential") return '#e05d5d';
                  if (param.value.Segment === "Champion") return '#d83534';
                }
              },
            }
          ]
        };


        setVal(option);


      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
        console.log(err);
    });

  }, [firmid, integration, t]);

  return (
    <>
      {val === null && serverMsg !== null && 
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>{t(serverMsg)}</span>
        </div>
      }
      {val !== null && serverMsg === null &&
        <ReactEcharts option={val} />      
      }
    </>

  );
}



