import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import { Text, View, ActivityIndicator } from 'react-native';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ListItemIcon } from '@material-ui/core';

import { addThousandSeperator } from "../components/ThousandSep";

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    width: "22%",
    backgroundColor: "#d03a39",
    color: "white",
    margin: "1em 1em 0em",
  },
  rootZero: {
    display: "flex",
    textAlign: "left",
    width: "526px !important",
    minWidth: "200px",
    backgroundColor: "#d03a39",
    color: "white",
    margin: "1em 1em 0em"
  },
  title: {
    fontSize: "1rem",
    color: "white"
  },
  rootTable: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    margin: "1.5em 1em 0em",
    borderRadius: "4px",
    border: "1px solid rgba(0,0,0,0.2)",
    width: "30%"
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  listSubHeaderRoot: {
    backgroundColor: 'white',
  }
});

var color = {
  1: '#a8a9ac',
  2: '#d03a39',
  3: '#262626',
  4: '#71737b',
  5: '#a8a9ac',
  6: '#d03a39',
  7: '#262626',
  8: '#71737b',
  9: '#a8a9ac',
  10: '#d03a39'
}

export default function SuppCards({ hvalue="100%" }) {

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  

  const [val, setVal] = useState(null);
  const [seg, setSeg] = useState([null, null, null, null]);

  const [lineGrap1, setLineGrap1] = useState(null);
  const [lineGrap2, setLineGrap2] = useState(null);
  const [lineGrap3, setLineGrap3] = useState(null);

  const [cust, setCustomers] = useState(null);

  const [customerSelected, setCustomerSelected] = useState("");

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;

  const classes = useStyles();

  function getColorofCard(index) {
    if(index > 10) {
      index = index % 1
    }

    return color[index]
  }

  const customerSelectedChange = event => {

    const customer_id  = event.target.value;

    setIsLoading(true);

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = {
        "path": null,
        "supplierid": String(customer_id)
      }
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gider/",
        "supplierid": String(customer_id)
      }
    }

    fetch(process.env.REACT_APP_REQ_IP + '/supplier_card_query/<supplierid>', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      setIsLoading(false);

      if(data.results) {

        const values_df = JSON.parse(data['df']);
        const seg_df = JSON.parse(data['seg_df']);
        const graph_df = JSON.parse(data['df_time']);

        const table1 = JSON.parse(seg_df[0]['Recommendations Based on Suppliers with Similar Behavior'].replace(/'/g, '"'))
        const table2 = JSON.parse(seg_df[0]['Segment(1) Expend Recommendation'].replace(/'/g, '"'))
        const table3 = JSON.parse(seg_df[0]['Segment(2) Expend Recommendation'].replace(/'/g, '"'))
        const table4 = JSON.parse(seg_df[0]['Segment(3) Expend Recommendation'].replace(/'/g, '"'))

        const total_grap = [];
        const quantity_grap = [];
        const unit_price_grap = [];

        graph_df.map((keys, value) => {

          total_grap.push({
            'x': keys.Date,
            'y': keys.Total.toFixed(2)
          });

          quantity_grap.push({
            'x': keys.Date,
            'y': keys.Quantity.toFixed(2)
          });

          unit_price_grap.push({
            'x': keys.Date,
            'y': keys.Unit_Price.toFixed(2)
          });

        });

        setLineGrap1(total_grap);
        setLineGrap2(quantity_grap);
        setLineGrap3(unit_price_grap);

        for (var [key, element] of Object.entries(values_df[0])) {
          if(typeof element == "number") {
            values_df[0][key] = addThousandSeperator(element)
          }
        }

        setVal(values_df[0]);
        setSeg([table1, table2, table3, table4]);

        setCustomerSelected(customer_id);
      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
        setIsLoading(false);
        setError(err);
    });
  };

  useEffect(() => {

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gider/",
      }
    }
  
    fetch(process.env.REACT_APP_REQ_IP + '/list_of_suppliers', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.results) {
        setCustomers(data['1chart'])
      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
      setIsLoading(false);
      setError(err);
    });

  }, [firmid, integration, t]);


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
        </View>
    );
  }
  if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
          </View>
      );
  }
  else {
  return (
    <>
      {cust !== null &&
      <div>
        <FormControl variant="outlined" className='w-50 my-4' required>
          <InputLabel id="select-ids">{t("Choose the Supplier")}</InputLabel>
          <Select
          labelId="select-ids"
          id="select_date"
          value={customerSelected}
          onChange={customerSelectedChange}
          label={t("Choose the Supplier")}>
              {cust.map((i) => (
                <MenuItem key={i} value={i}>{i.length > 50 ? i.substring(0,50) + "..." : i}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      }

      {cust === null &&
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>Data is invalid</span>
          {serverMsg !== null &&
              <span>{t(serverMsg)}</span>
          }
        </div>
      }
      {val !== null && val !== undefined && serverMsg === null &&
        <div className='row'>
          {Object.entries(val)?.map(([keys, value], index) => 
          <div className="card-list-div" key={index}>
          {index === 0 &&
            <Card className={classes.rootZero}>
              <CardContent className='d-flex pb-3 pr-4'>
                <div className='flex-column'>
                  <AssignmentIndIcon className='special-card-icon'/>
                </div>

                <div className='flex-column align-self-center'>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {t(keys)}
                    </Typography>
                    <Typography variant="inherit" component="h6">
                      {value}
                  </Typography>
                </div>

              </CardContent>
            </Card> 
          }
          {index !== 0 &&
            <Card className={classes.root} style={{"backgroundColor": getColorofCard(index)}}>
              <CardContent className='pb-3'>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {t(keys)}
                  </Typography>
                  <Typography variant="inherit" component="h4">
                    {value}
                  </Typography>
              </CardContent>
            </Card> 
          }
          </div>
          )}
        </div>
      }

      {lineGrap1 !== null && serverMsg === null &&
      
      <>
      <Typography variant="h3" className="bar-title mt-5">
        {t("Total Purchase Analysis")}
      </Typography>

      <div className="container pages-container">

      <Chart
          type="line"
          height={hvalue}
          options={
            {
              chart: {
                animations: {
                  enabled: false
                },
                stacked: false,
                zoom: {
                  autoScaleYaxis: true,
                  autoScaleXaxis: true
                },
                parentHeightOffset: 0,
                background: themeBack
              },
              theme: {
                mode: themeMode, 
            },          
              colors: ['#33cd32'],
              fill: {
                opacity: [1]
              },
              stroke: {
                curve: 'straight',
                width: [4]
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                },
                background: {
                  enabled: true,
                  foreColor: '#fff',
                  borderRadius: 2,
                  padding: 4,
                  opacity: 0.9,
                  borderWidth: 1,
                  borderColor: '#fff'
                },
                formatter: function (val, opts) {
                  return addThousandSeperator(val)
                }
              },
              tooltip: {
                y: [                
                {
                  formatter: function(value) {
                    return addThousandSeperator(value)
                  }
                }
              ]
              },  
              xaxis: {
                type: 'datetime',
                labels: {
                  formatter: function(value, timestamp, index) {
                    return Intl.DateTimeFormat('ja-JP', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(value)
                  },
                },
                tickPlacement: "on",
                title: {
                  text: t("Monthly Dates"),
                }
              },    
              yaxis: {
                title: {
                  text: t('Total Purchase'),
                },
                labels: {
                  formatter: function(value) {
                    if(value !== null){
                      return addThousandSeperator(value);
                    }
                  }
                }
              },
            }
          }
          series={[{
            data: lineGrap1,
            name : t("Total Sales")
          }]}
        />
        </div>
      </>
      }

    {lineGrap2 !== null && serverMsg === null &&
      
      <>
      <Typography variant="h3" className="bar-title mt-5">
        {t("Quantity Analysis")}
      </Typography>

      <div className="container pages-container">

      <Chart
          type="line"
          height={hvalue}
          options={
            {
              chart: {
                animations: {
                  enabled: false
                },
                stacked: false,
                zoom: {
                  autoScaleYaxis: true,
                  autoScaleXaxis: true
                },
                parentHeightOffset: 0,
                background: themeBack
              },
              theme: {
                mode: themeMode, 
            },          
              colors: ['#0064ff'],
              fill: {
                opacity: [1]
              },
              stroke: {
                curve: 'straight',
                width: [4]
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                },
                background: {
                  enabled: true,
                  foreColor: '#fff',
                  borderRadius: 2,
                  padding: 4,
                  opacity: 0.9,
                  borderWidth: 1,
                  borderColor: '#fff'
                },
                formatter: function (val, opts) {
                  return addThousandSeperator(val)
                }
              },
              tooltip: {
                y: [                
                {
                  formatter: function(value) {
                    return addThousandSeperator(value)
                  }
                }
              ]
              }, 
              xaxis: {
                type: 'datetime',
                labels: {
                  formatter: function(value, timestamp, index) {
                    return Intl.DateTimeFormat('ja-JP', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(value)
                  },
                },
                tickPlacement: "on",
                title: {
                  text: t("Monthly Dates"),
                }
              },   
              yaxis: {
                title: {
                  text: t('Quantity'),
                },
                labels: {
                  formatter: function(value) {
                    if(value !== null){
                      return addThousandSeperator(value);
                    }
                  }
                },
              },
            }
          }
          series={[{
            data: lineGrap2,
            name : t("Quantity")
          }]}
        />
        </div>
      </>
      }

    {lineGrap3 !== null && serverMsg === null &&
      
      <>
      <Typography variant="h3" className="bar-title mt-5">
        {t("Unit Price Analysis")}
      </Typography>

      <div className="container pages-container">

      <Chart
          type="line"
          height={hvalue}
          options={
            {
              chart: {
                animations: {
                  enabled: false
                },
                stacked: false,
                zoom: {
                  autoScaleYaxis: true,
                  autoScaleXaxis: true
                },
                parentHeightOffset: 0,
                background: themeBack
              },
              theme: {
                mode: themeMode, 
            },          
              colors: ['#ff7e0e'],
              fill: {
                opacity: [1]
              },
              stroke: {
                curve: 'straight',
                width: [4]
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                },
                background: {
                  enabled: true,
                  foreColor: '#fff',
                  borderRadius: 2,
                  padding: 4,
                  opacity: 0.9,
                  borderWidth: 1,
                  borderColor: '#fff'
                },
                formatter: function (val, opts) {
                  return addThousandSeperator(val)
                }
              },
              tooltip: {
                y: [                
                {
                  formatter: function(value) {
                    return addThousandSeperator(value)
                  }
                }
              ]
              }, 
              xaxis: {
                type: 'datetime',
                labels: {
                  formatter: function(value, timestamp, index) {
                    return Intl.DateTimeFormat('ja-JP', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(value)
                  },
                },
                tickPlacement: "on",
                title: {
                  text: t("Monthly Dates"),
                }
              },    
              yaxis: {
                title: {
                  text: t('Unit Price'),
                },
                labels: {
                  formatter: function(value) {
                    if(value !== null){
                      return addThousandSeperator(value);
                    }
                  }
                }
              },
            }
          }
          series={[{
            data: lineGrap3,
            name : t("Unit Price")
          }]}
        />
        </div>
      </>
      }


    {(seg[0] !== null || seg[1] !== null || seg[2] !== null || seg[3] !== null) && 
      <>
          <Typography variant="h3" className="bar-title mt-5">
          {t("Supplier & Expend Recommendations")}
          </Typography>

          <div className='row'>

          {seg[1] !== null && serverMsg === null &&
            <List className={classes.rootTable} subheader={<li />}>
              <ListSubheader style={{backgroundColor: themeBack}}>{t("Segment(1) Expend Recommendation")}</ListSubheader>
              <ul className={classes.ul}>
              {seg[1]?.map((sectionId) => (
                    <ListItem key={sectionId} >
                        <ListItemIcon className='card-icon-2'>
                          <ArrowForwardIosIcon className='special-card-icon-2' />
                        </ListItemIcon>
                      <ListItemText primary={sectionId} />
                    </ListItem>
              ))}
                </ul>
            </List>
          }

          {seg[2] !== null && serverMsg === null &&
            <List className={classes.rootTable} subheader={<li />}>
              <ListSubheader style={{backgroundColor: themeBack}}>{t("Segment(2) Expend Recommendation")}</ListSubheader>
              <ul className={classes.ul}>
              {seg[2]?.map((sectionId) => (
                    <ListItem key={sectionId}>
                      <ListItemIcon className='card-icon-2'>
                          <ArrowForwardIosIcon className='special-card-icon-2' />
                        </ListItemIcon>
                      <ListItemText primary={sectionId} />
                    </ListItem>
              ))}
                </ul>
            </List>
          }

          {seg[3] !== null && serverMsg === null &&
            <List className={classes.rootTable} subheader={<li />}>
              <ListSubheader style={{backgroundColor: themeBack}}>{t("Segment(3) Expend Recommendation")}</ListSubheader>
              <ul className={classes.ul}>
              {seg[3]?.map((sectionId) => (
                    <ListItem key={sectionId}>
                      <ListItemIcon className='card-icon-2'>
                          <ArrowForwardIosIcon className='special-card-icon-2' />
                        </ListItemIcon>
                      <ListItemText primary={sectionId} />
                    </ListItem>
              ))}
                </ul>
            </List>
          }

          {seg[0] !== null && serverMsg === null &&
            <List className={classes.rootTable} subheader={<li />}>
              <ListSubheader style={{backgroundColor: themeBack}}>{t("Recommendations Based on Suppliers with Similar Behavior")}</ListSubheader>
              <ul className={classes.ul}>
              {seg[0]?.map((sectionId) => (
                    <ListItem key={sectionId}>
                      <ListItemIcon className='card-icon-2'>
                          <ArrowForwardIosIcon className='special-card-icon-2' />
                        </ListItemIcon>
                      <ListItemText primary={sectionId} />
                    </ListItem>
              ))}
                </ul>
            </List>
          }
          </div>
        </>        
    }


    </>

    );
  }
}