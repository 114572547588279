import {React} from 'react';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import CustJourney from '../../charts/customer_journey';


export default function CustomerJourney(packageType, msg="") {
  const { t, i18n } = useTranslation();

  return (   
    <> 

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Customer Journey")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <CustJourney hvalue="150%"/>
        </Card>
      </div>
      
    </>
  );
}
