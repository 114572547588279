import React from 'react';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import PurchaseImportanceMonthly from '../../charts/purchase_importance_monthly';

export default function PurchaseFinancialExpo() {

  const { t, i18n } = useTranslation();

  return (   
    <> 
      <div className='d-flex w-100'>
        <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Purchase Forecast Factor Analysis by Time")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <PurchaseImportanceMonthly hvalue="300%"/> 
          </Card>
      </div>
      
    </>
  );
}
