import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Text, View, ActivityIndicator } from 'react-native';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';

import { useTranslation } from "react-i18next";

import { handleLogout } from '../components/Logout.js';

import './Packages.css';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { withStyles } from '@material-ui/core/styles';

const PackageSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#d83534',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#d83534',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#d83534",
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


function Packages() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [packagePeriod, setPackagePeriod] = useState(true);

    const { t, i18n } = useTranslation();

    const firmid = window.sessionStorage.getItem('comp_id');
    const uid = window.sessionStorage.getItem('uid');

    const role = window.sessionStorage.getItem("role");

    //if user not authenticated
    if(uid === null) {
        handleLogout();
        navigate("/login", { replace: true });
    }

    const handlePackagePeriodChange = (event) => {
      setPackagePeriod(event.target.checked);
    };
    
    function handleBuyCredit(e) { 

        setIsLoading(true);

        const selected_package = e.target.value;

        const list_obj = {
            "uid": uid,
            "firmid": firmid,
            "package": selected_package,
            "period": packagePeriod
        }

        fetch(process.env.REACT_APP_REQ_IP + '/buy_credit', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);

            if(data.result === false) {
              enqueueSnackbar(t(data.message), { 
                variant: 'error',
                persist: false
              });
            }
            else if (data.result === true) {
              enqueueSnackbar(t(data.message), { 
                variant: 'success',
                persist: false
              });
            }
            else {
              if(data.result === null || data.result === "null") {
                enqueueSnackbar(t("Please check your internet connection and try again."), { 
                  variant: 'error',
                  persist: false
                });
              }
              else {
                window.location.replace(data.result);
              }
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }


    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#d83534" />
            </View>
        );
    }

    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
          <>
          <AppBar className="profile-dialog-bar">
              <Toolbar>
                <Typography variant="h6" className="d-flex ml-2">
                      {t("Packages")}
                  </Typography>

                  <Button className='back-home-btn' type="button" href='/'>{t('Back to Home')}</Button>   
              </Toolbar>
          </AppBar>

          <FormGroup className='switch-container' row>
            <Typography>Yearly</Typography>
            <FormControlLabel className='switch-formControlLabel' control={<PackageSwitch checked={packagePeriod} onChange={handlePackagePeriodChange} name="monthly" />}/>
            <Typography>Monthly</Typography>
          </FormGroup>

          <div className="package-container" style={{marginTop: "1em"}}>
            <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
            <header className="package-container-header"></header>
            <div className="package-table centered">
              <div className="row package-row">
                <div className="package-column">
                  <ul className="price">
                    <li className="header package-header">
                      <br />
                      <br />
                      {t("Features")}
                    </li>
                    <li>{t("Financial Analysis on a Weekly and Monthly Basis")}</li>
                    <li>{t("Next 12 Months Forecast")}</li>

                    <li>{t("Year-End Income, Expense, Profitability** Financial Forecasts")}</li>
                    <li>{t("Financial Seasonality Analysis")}</li>

                    <li>{t("Financial Trend Analysis")}</li>  
                    <li>{t("Sales Amount Estimates")}</li>

                    <li>{t("Customer/Sale Point Segmentation")}</li>
                    <li>{t("Detection of Customers at Risk About to Lose")}</li>

                    <li>{t("Loyal and Star Customer/Sales Point Identification")}</li>
                    <li>{t("3D Customer/Sale Point Discovery")}</li>

                    <li>{t("Automated Customer/Sale Cards and Customer/Sale Specific Analytics")}</li>

                    <li>{t("Customer/Sale Point Anomaly Detection")}</li>
                    <li>{t("Product/Service Recommendation Specific to Customer/Sale Point")}</li>

                    <li>{t("Product/Service Segmentation")}</li>
                    <li>{t("Detecting Frozen Products")}</li>

                    <li>{t("Loyal and Star Product Detection")}</li>
                    <li>{t("3D Product/Service Analysis")}</li>

                    <li>{t("Detection of Separated Products")}</li>
                    <li>{t("Product/Service Specific Customer/Sales Point Recommendation")}</li>

                    <li>{t("Sales Personnel Performance Comparison*")}</li>
                    <li>{t("Automatically Generated Sales Personnel Cards*")}</li>

                    <li>{t("Sales Personnel Performance, Trend, Seasonality Analyzes*")}</li>
                    <li>{t("Supplier Analysis and Supplier Segmentation**")}</li>

                    <li>{t("Supplier Anomaly Detection**")}</li>
                    <li>{t("Automatically Generated Supplier Cards**")}</li>

                    <li>{t("Profitability Analysis**")}</li>
                    <li>{t("Product Based Profitability, Trend and Seasonality Analyzes**")}</li>

                    <li>{t("Personalized Dashboard and Report Interfaces")}</li>
                    <li>{t("CSV and PNG Output of Report Graphics")}</li>

                    <li>{t("Paraşüt Integration")}</li>
                    <li>{t("EDM Integration")}</li>

                    <li>{t("BizimHesap Integration")}</li>
                    <li>{t("e-Invoice XML File Integration")}</li>

                    <li>{t("Data Uploading Module with Excel")}</li>
                    <li>{t("Get Excel Outputs of All Analyzes")}</li>

                    <li>{t("Database Integration")}</li>
                    <li>{t("AI Engine Updates")}</li>

                    <li>{t("Storage Space")}</li>
                    <li>{t("Maximum Number of Customers/Sales Points")}</li>

                    <li>{t("Maximum Number of Products/Services")}</li>
                    <li>{t("Number of Users")}</li>
                  </ul>
                </div>
      
                <div className="package-column">
                  <ul className="price">
                    <li className="header package-header package-header-witdh">
                      {t("Freemium")}
                      <br />
                      <span className="dollar price-title">0</span>
                      {packagePeriod &&
                        <p className='package-p'>{t("Monthly")}</p>
                      }
                      {!packagePeriod &&
                        <p className='package-p'>{t("Yearly")}</p>
                      }
                      <div className="button_cont" align="center">
                        {role === "firmAdmin" && 
                          <button className="btn package-btn" onClick={handleBuyCredit} value="free" type="button" rel="nofollow">
                            {t("Freemium Plan")}
                          </button>
                        }
                        {role !== "firmAdmin" &&
                          <button className="btn package-btn" value="free" type="button" rel="nofollow" disabled>
                            {t("Freemium Plan")}
                          </button>                         
                        }

                      </div>
                    </li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li><CloseIcon style={{color: "red"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li>{t("Monthly")}</li>
                    <li>100MB</li>

                    <li>300</li>
                    <li>300</li>

                    <li>1</li>

                  </ul>
                </div>
      
                <div className="package-column">
                  <ul className="price">
                    <li className="header package-header package-header-witdh">
                    {t("Silver")}
                      <br />
                      {packagePeriod &&
                        <span className="dollar price-title">8500</span>
                      }
                      {!packagePeriod &&
                        <span className="dollar price-title">85000</span>
                      }
                      {packagePeriod &&
                        <p className='package-p'>{t("Monthly")}</p>
                      }
                      {!packagePeriod &&
                        <p className='package-p'>{t("Yearly")}</p>
                      }
                      <div className="button_cont" align="center">
                        {role === "firmAdmin" && 
                          <button className="btn package-btn"  onClick={handleBuyCredit} value="silver" type='button' rel="nofollow noopener">
                          {t("Silver Plan")}
                          </button>
                        }
                        {role !== "firmAdmin" &&
                          <button className="btn package-btn" value="silver" type='button' rel="nofollow noopener" disabled>
                          {t("Silver Plan")}
                          </button>                      
                        }

                      </div>
                    </li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li>{t("Weekly")}</li>
                    <li>1GB</li>

                    <li>1000</li>
                    <li>1000</li>

                    <li>1</li>
                  </ul>
                </div>

                <div className="package-column">
                  <ul className="price">
                    <li className="header package-header package-header-witdh">
                      {t("Gold")}
                      <br />
                      {packagePeriod &&
                        <span className="dollar price-title">13000</span>
                      }
                      {!packagePeriod &&
                        <span className="dollar price-title">130000</span>
                      }
                      {packagePeriod &&
                        <p className='package-p'>{t("Monthly")}</p>
                      }
                      {!packagePeriod &&
                        <p className='package-p'>{t("Yearly")}</p>
                      }
                      <div className="button_cont" align="center">
                        {role === "firmAdmin" && 
                          <button className="btn package-btn"  onClick={handleBuyCredit} value="gold" type='button' rel="nofollow noopener">
                          {t("Gold Plan")}
                          </button>
                        }
                        {role !== "firmAdmin" &&
                          <button className="btn package-btn" value="gold" type='button' rel="nofollow noopener" disabled>
                          {t("Gold Plan")}
                          </button>                   
                        }

                      </div>
                    </li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li>{t("Every 5 Day")}</li>
                    <li>2GB</li>

                    <li>3000</li>
                    <li>3000</li>

                    <li>3</li>
                  </ul>
                </div>


                <div className="package-column">
                  <ul className="price">
                    <li className="header package-header package-header-witdh">
                    {t("Platin")}
                      <br />
                      {packagePeriod &&
                        <span className="dollar price-title">15500</span>
                      }
                      {!packagePeriod &&
                        <span className="dollar price-title">155000</span>
                      }
                      {packagePeriod &&
                        <p className='package-p'>{t("Monthly")}</p>
                      }
                      {!packagePeriod &&
                        <p className='package-p'>{t("Yearly")}</p>
                      }
                      <div className="button_cont" align="center">
                        {role === "firmAdmin" && 
                          <button className="btn package-btn"  onClick={handleBuyCredit} value="platin" type='button' rel="nofollow noopener">
                          {t("Platin Plan")}
                          </button>
                        }
                        {role !== "firmAdmin" &&
                          <button className="btn package-btn" value="platin" type='button' rel="nofollow noopener" disabled>
                          {t("Platin Plan")}
                          </button>                 
                        }

                      </div>
                    </li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><CloseIcon style={{color: "red"}} /></li>

                    <li>{t("Everday")}</li>
                    <li>5GB</li>

                    <li>5000</li>
                    <li>5000</li>

                    <li>5</li>
                  </ul>
                </div>

                <div className="package-column">
                  <ul className="price">
                    <li className="header package-header package-header-witdh">
                    {t("Special")}
                      <br />
                      <span className='price-title'>{t("Contact Us")}</span>
                      <p className='package-p'></p>
                      <div className="button_cont" align="center">
                        <a className="btn package-btn" href="https://kolay.ai/index.php/iletisim/" value="special" type='button' rel="nofollow noopener">
                        {t("Special Plan")}
                        </a>
                      </div>
                    </li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li>

                    <li><DoneAllIcon style={{color: "green"}} /></li>
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li><DoneAllIcon style={{color: "green"}} /></li> 
                    <li><DoneAllIcon style={{color: "green"}} /></li> 

                    <li>{t("Unlimited")}</li>
                    <li>{t("Unlimited")}</li>

                    <li>{t("Unlimited")}</li>
                    <li>{t("Unlimited")}</li>

                    <li>{t("Unlimited")}</li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <p className='package-footer-info pb-1'>{t("*In Case of Sales Personnel Data Exist")}</p>
          <p className='package-footer-info mb-5'>{t("**In case of Uploading/Integrating Supplier Data (Expense Invoices)")}</p>
        </>
        );

    }

}

export default Packages;