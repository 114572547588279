import {React} from 'react';
import { Image } from 'react-native';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import Top3ExpendBySegment from '../../charts/top3_expend_by_segment';
import Top3ExpendBySegmentTable from '../../charts/top3_expend_by_segment_table';
import Top3ExpendBySegmentLast from '../../charts/top3_expend_by_segment_last';
import Top3ExpendBySegmentTableLast from '../../charts/top3_expend_by_segment_table_last';

import SeasonalityImg from "../../assets/img/season.png";

export default function ExpendSegAnalytics(packageType, msg="") {
  const { t, i18n } = useTranslation();

  return (   
    <> 

      { packageType === "premium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
                {t("Top 3 Expend By Segment")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <Top3ExpendBySegment hvalue="250%"/>
          </Card>
        </div> 
      }

      {packageType === "freemium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
              {t("Top 3 Expend By Segment")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>

            <div className="blur-container"> 
              <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
              <a type='button' className="btn" href='/packages'>{t(msg)}</a>
            </div>

          </Card>
        </div>
      }

      <div>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Top 3 Expend in Supplier Segments")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <Top3ExpendBySegmentTable hvalue="300%"/>
        </Card>
      </div> 


      { packageType === "premium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
                {t("Top 3 Expend By Segment (Last Month)")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <Top3ExpendBySegmentLast hvalue="250%"/>
          </Card>
        </div> 
      }

      {packageType === "freemium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
              {t("Top 3 Expend By Segment (Last Month)")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>

            <div className="blur-container"> 
              <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
              <a type='button' className="btn" href='/packages'>{t(msg)}</a>
            </div>

          </Card>
        </div>
      }

    <div>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Top 3 Expend in Supplier Segments (Last Month)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <Top3ExpendBySegmentTableLast hvalue="300%"/>
        </Card>
      </div> 
    </>
  );
}
