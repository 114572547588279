import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import { useTheme } from '@material-ui/core/styles';

export default function RFMQuartileProduct({ hvalue="300%" }) {

  const [dataArr, setDataArr] = useState(null);

  const [minX, setMinX] = useState(null);
  const [minY, setMinY] = useState(null);
  const [maxX, setMaxX] = useState(null);
  const [maxY, setMaxY] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;


  useEffect(() => {

    const ac = new AbortController();

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gelir/"
      }
    }

    var segments_dict = [
      'Hibernate', 
      'Risk', 
      'Cant Lose',
      'Sleeper',
      'Need Attention',
      'Loyal',
      'Promising',
      'New Customer',
      'High Potential',
      'Champion'
    ]
  
    fetch(process.env.REACT_APP_REQ_IP + '/rfm_quartile_product', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.results) {

        const values = JSON.parse(data.df);

        const min_x = JSON.parse(data.min_x);
        const min_y = JSON.parse(data.min_y);
        const max_x = JSON.parse(data.max_x);
        const max_y = JSON.parse(data.max_y);

        setMinX(min_x);
        setMinY(min_y);
        setMaxX(max_x);
        setMaxY(max_y);

        const tmp_dataArr = [];

        segments_dict.forEach((segment_name, index) => {

          var buble1 = values.filter(el => el.Segment === segment_name);

          var temp_data = []
          var labels = []
      
          buble1.map(element => {
            temp_data.push([element.recency, element.frequency, element.T]);
            labels.push(element.Description);
          });

          var data = {
            'name': t(segment_name),
            'data': temp_data,
            't2': labels
          };

          tmp_dataArr.push(data);

        });

        setDataArr(tmp_dataArr);

      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
        console.log(err);
    });

    return () => ac.abort();

  }, [firmid, integration, t]);


  return (
    <>
      {dataArr === null && serverMsg !== null && 
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>{t(serverMsg)}</span>
        </div>
      }
      {dataArr !== null && serverMsg === null &&
      <Chart
        type="bubble"
        height={hvalue}
        options= {{          
            chart: {
              animations: {
                enabled: true
              },
              stacked: false,
              parentHeightOffset: 0,
              background: themeBack
            },
            theme: {
              mode: themeMode, 
            },  
            colors: ['#003280', '#67a3ff', 
            '#d83534', '#33cd32', 
            '#0064ff', '#294166',
            '#ff7e0e', '#6c1b1a',
            '#249023', '#70dc70',
            '#cce0ff', '#d6f5d6',
            '#ffe5cf', '#0f3d0f',
            '#b3580a', '#e05d5d',
            '#707070', '#FF6262'
            ],
            dataLabels: {
                enabled: false
            },
            fill: {
                opacity: 0.8
            },
            xaxis: {
                tickAmount: 12,
                min: minX - 100,
                max: maxX + 100
                // type: 'category',
            },
            yaxis: {
                min: minY - 100,
                max: maxY + 100
            },
            tooltip: {
              theme: themeMode,
              custom: function({series, seriesIndex, dataPointIndex, w}) {
                var data = w.globals.initialSeries[seriesIndex].t2[dataPointIndex];
                var data2 = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                
                return '<ul class="ctooltip">' +
                          '<li><b>' + t("ProductID") + '</b>: ' + data + '</li>' +
                          '<li><b>' + t("Recency") + '</b>: ' + data2[0] + '</li>' +
                          '<li><b>' + t("Frequency") + '</b>: \'' + data2[1] + '\'</li>' +
                        '</ul>';
              }
            }
          }
        }
        series={
          dataArr
        }
        />
      }
    </>

  );
}