import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import * as Components from "../assets/js/auth";
import { handleLogout } from "../components/Logout";

import LangSwitch from '../components/LangSwitch';
import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FirstLogin() {

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [integ, setInteg] = useState();
    const [open, setOpen] = useState(false);
    const [btnstatus, setBtnstatus] = useState(true);

    const [uploadFile, setUploadFile] = useState();
    const [uploadFile2, setUploadFile2] = useState();

    const [apiKey, setapiKey] = useState('');
    const [secretKey, setsecretKey] = useState('');
    const [userName, setuserName] = useState('');
    const [passWord, setpassWord] = useState('');
    const [invoiceType, setInvoiceType] = useState('satis');

    const { t, i18n } = useTranslation();

    let navigate = useNavigate();

    const uid = window.sessionStorage.getItem("uid");
    const email = window.sessionStorage.getItem("email");
    const firmid = window.sessionStorage.getItem("comp_id");

    if(uid === null) {
        handleLogout();
        navigate("/login", { replace: true });
    }

    const handleBackLogin = () => {
        handleLogout();
        navigate("/login", { replace: true });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = event => {

        const value  = event.target.value;
        setInteg(value);

        setuserName('');
        setpassWord('');
        setapiKey('');
        setsecretKey('');
        setUploadFile();
        setUploadFile2();

        if(value !== "1") {
            setOpen(true);
        }
        else if(value === "1") {
            setOpen(false);
            updateIntegrationType(1);
        }
    };

    const handleFile = (event) => {
        const file_size = event.target?.files[0]?.size;

        if(file_size) {
            
            //TODO: Role e göre file size kontrol edilecek, bunu cookie üzerinden ön kontrol edeceğiz fakat sonradan backendde de edilecek.
            const file_size_mb = (file_size / (1024*1024));
            if(file_size_mb > 1000) {
                enqueueSnackbar(t("Your file must be under 1 GB. Please upload a smaller .zip file."), { 
                    variant: 'error',
                    persist: false
                });
    
                setBtnstatus(false);
            }
            else {
                enqueueSnackbar(t("File successfully uploaded. Dont forget to save."), { 
                    variant: 'info',
                    persist: false
                });

                if(event.target.id === "data_xlsx" || event.target.id === "data_zip") {
                    setUploadFile(event.target.files[0]);
                }
                else if(event.target.id === "data_xlsx_spending" || event.target.id === "data_zip_spending") {
                    setUploadFile2(event.target.files[0]);
                }
                else {
                    console.log("Dosya tipi seçenekler arasında mevcut değil.")
                }

                setBtnstatus(true);
            }
        }

	};

    var pack = window.sessionStorage.getItem("pack")

    const opts = {
        1: t("Show demo data"),
        2: t("Excel file upload"),
        3: t("Upload e-invoices (.zip of xml files)"),
        4: t("BizimHesap integration"),
        5: t("Paraşüt integration"),
        6: t("EDM integration"),
        7: t("QNB Finansbank integration")
    }

    function updateIntegrationType(type) {
        const list_obj = {
            'firmid': firmid,
            'isFirstLogin': type
        }

        fetch(process.env.REACT_APP_REQ_IP + '/update_isfirst_login', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {

            setIsLoading(false);

            if(data.result === true) {

                enqueueSnackbar(t(data.message), { 
                    variant: 'success',
                    persist: false
                });

                setInteg(type);
                window.sessionStorage.setItem("integration", type);

                if(pack == "free") {
                    navigate("/sandbox", { replace: true });
                }
                else {
                navigate("/app", { replace: true });
                }
            }
            else {
                enqueueSnackbar(t(data.message), { 
                    variant: 'error',
                    persist: false
                });
            }

        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }

    const handleUpdatePackage = () => {

        setIsLoading(true);

        if(integ === "2" || integ === "3") {

            const formData = new FormData()
            formData.append('files', uploadFile)
            formData.append('files', uploadFile2)

            fetch(process.env.REACT_APP_REQ_IP + '/upload_file?firmid=' + firmid + '&integrationType=' + String(integ) + '&isFirstLogin=0', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/zip'}, body: formData}).then(res => res.json()).then(data => {
                
                setIsLoading(false);

                if(data.result) {

                    enqueueSnackbar(t(data.message), { 
                        variant: 'success',
                        persist: false
                    });

                    updateIntegrationType(integ);

                }
                else {

                    enqueueSnackbar(t(data.message), { 
                        variant: 'error',
                        persist: false
                    });
                }

            })
            .catch(err => {
                setIsLoading(false);
                setError(err);
            }); 

        }
        else {

            const integ_list = {
                "4": "bizimhesap",
                "5": "paraşüt",
                "6": "edm",
                "7": "qnb"
            }

            const update_list_obj = {
                "firmid": firmid,
                "isFirstLogin": "0",
                "integrationType": integ,
                "app_api_key": apiKey,
                "app_secret_key": secretKey,
                "app_username": userName,
                "app_passw": passWord,
                "app_name": integ_list[integ],
                "app_invoice_type": invoiceType
            }

            fetch(process.env.REACT_APP_REQ_IP + '/update_account_info', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(update_list_obj)}).then(res => res.json()).then(data => {

                setIsLoading(false);

                if(data.result === true) {

                    setInteg(integ);
                    window.sessionStorage.setItem("integration", integ);

                    if(integ === 4) {

                        var list_obj_2 = {
                            'firmid': firmid,
                            'invoice_type': invoiceType,
                            'path': "./files/"+firmid+"/",
                        }
                
                        fetch(process.env.REACT_APP_REQ_IP + '/bizimhesap', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj_2)}).then(res => res.json()).then(data => {
                
                            setIsLoading(false);
                
                            if(data.result === true) {
                
                                enqueueSnackbar(t(data.message), { 
                                    variant: 'success',
                                    persist: true
                                });
                    
                                if(pack == "free") {
                                    navigate("/sandbox", { replace: true });
                                }
                                else {
                                navigate("/app", { replace: true });
                                }
            
                            }
                            else {
                                enqueueSnackbar(t(data.message), { 
                                    variant: 'error',
                                    persist: false
                                });
                            }
                
                        })
                        .catch(err => {
                            setIsLoading(false);
                            setError(err);
                        });

                    }
                    else {

                        var list_obj_2 = {
                            "firmid": firmid,
                            "path": "./files/"+firmid+"/",
                            "app_api_key": apiKey,
                            "app_secret_key": secretKey,
                            "app_username": userName,
                            "app_password": passWord
                        }

                        fetch(process.env.REACT_APP_REQ_IP + '/parasut_integration', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj_2)}).then(res => res.json()).then(data => {
                
                            setIsLoading(false);
                
                            if(data.result === true) {
                
                                enqueueSnackbar(t(data.message), { 
                                    variant: 'success',
                                    persist: true
                                });
                
                                if(pack == "free") {
                                    navigate("/sandbox", { replace: true });
                                }
                                else {
                                navigate("/app", { replace: true });
                                }
                
                            }
                            else {
                                enqueueSnackbar(t(data.message), { 
                                    variant: 'error',
                                    persist: false
                                });
                            }
                
                        })
                        .catch(err => {
                            setIsLoading(false);
                            setError(err);
                        });
                    }


                }
                else {
                    enqueueSnackbar(t(data.message), { 
                        variant: 'error',
                        persist: false
                    });
                }

            })
            .catch(err => {
                setIsLoading(false);
                setError(err);
            });

        }

        setOpen(false);
    };
    

    if (isLoading) {
        return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
          </View>
        );
    }
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className='login-body'>  
              <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
              <Components.Container>
                    <LangSwitch />
                    <div className='d-flex flex-column'>
                        <Components.Title className='mt-5 mb-2 align-self-center'>{t("Select one of the options to proceed.")}</Components.Title>
                        <p className='mb-4 align-self-center'>{t("You can change this option later on the 'Profile' page.")}</p>
                        <Components.Ghost2Button className='align-self-center mb-2 w-50' type="button" onClick={handleOpen} value={1}>{t("Show demo data")}</Components.Ghost2Button>
                        <Components.Ghost2Button className='align-self-center mb-2 w-50' type="button" onClick={handleOpen} value={2}>{t("Excel file upload")}</Components.Ghost2Button>
                        <Components.Ghost2Button className='align-self-center mb-2 w-50' type="button" onClick={handleOpen} value={3}>{t("Upload e-invoices (.zip of xml files)")}</Components.Ghost2Button>
                        <Components.Ghost2Button className='align-self-center mb-2 w-50' type="button" onClick={handleOpen} value={4}>{t("BizimHesap integration")}</Components.Ghost2Button>
                        <Components.Ghost2Button className='align-self-center mb-2 w-50' type="button" onClick={handleOpen} value={5}>{t("Paraşüt integration")}</Components.Ghost2Button>
                        <Components.Ghost2Button className='align-self-center mb-4 w-50 disable-btn' type="button" onClick={handleOpen} disabled value={6}>{t("EDM integration")} <sup className="text-white">{t("Coming soon")}</sup></Components.Ghost2Button>

                        <Components.Anchor className='align-self-center btn-back' onClick={handleBackLogin}>{t("Back to Login")}</Components.Anchor>
                    </div>

                    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                        <AppBar className="profile-dialog-bar">
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className="d-flex ml-2">
                                    {opts[integ]}
                                </Typography>

                                {btnstatus &&
                                    <Button className='profile-dialog-bar-btn' type="button" onClick={handleUpdatePackage}>
                                        {t('Save')}
                                    </Button>                                     
                                }

                                {!btnstatus &&
                                    <Button className='profile-dialog-bar-btn' type="button" onClick={handleUpdatePackage} disabled>
                                        {t('Save')}
                                    </Button>                                     
                                }
                            </Toolbar>
                        </AppBar>

                        {integ === "2" &&
                            <div className='profile-dialog-body'>

                                <div className='mb-1'>{t("Upload your data as an excel file.")}</div>
                                <div className='mb-2'> {t("You can download the sample file format by clicking the link.")}</div>

                                <a className='mb-4' href='https://bilkav.com/kolayai_ornek.xlsx'>{t("Sample file link")}</a>

                                <input
                                    onChange={handleFile}
                                    accept=".xlsx"
                                    className="d-none"
                                    id="data_xlsx"
                                    type="file"
                                    filename="income"
                                />
                                <label htmlFor="data_xlsx">
                                    <Button variant="contained" className='profile-save-btn mx-1' component="span">
                                        {t("Income File Upload")}
                                    </Button>
                                </label>

                                <input
                                    onChange={handleFile}
                                    accept=".xlsx"
                                    className="d-none"
                                    id="data_xlsx_spending"
                                    type="file"
                                    filename="sending"
                                />
                                <label htmlFor="data_xlsx_spending">
                                    <Button variant="contained" className='profile-save-btn mx-1' component="span">
                                        {t("Spending File Upload")}
                                    </Button>
                                </label>

                            </div>
                        }


                        {integ === "3" &&
                            <div className='profile-dialog-body'>

                                <div className='mb-1'>{t("Upload your data as an zip of xml files.")}</div>
                                <div className='mb-4'> {t("You can download the zip file from your e-fatura panel.")}</div>

                                <div className='row'>

                                    <input
                                        onChange={handleFile}
                                        accept=".zip"
                                        className="d-none"
                                        id="data_zip"
                                        type="file"
                                    />
                                    <label htmlFor="data_zip">
                                        <Button variant="contained" className='profile-save-btn mx-1' component="span">
                                            {t("Income File Upload")}
                                        </Button>
                                    </label>


                                    <input
                                        onChange={handleFile}
                                        accept=".zip"
                                        className="d-none"
                                        id="data_zip_spending"
                                        type="file"
                                    />
                                    <label htmlFor="data_zip_spending">
                                        <Button variant="contained" className='profile-save-btn mx-1' component="span">
                                            {t("Spending File Upload")}
                                        </Button>
                                    </label>

                                </div>

                            </div>
                        }

                        {integ === "4" &&          
                            <div className='profile-dialog-body'>
                                <TextField className='w-25 my-2' id="bizimh_username" type="text" label={t("BizimHesap Email")} variant="outlined" onChange={(e) => setuserName(e.target.value)}/>
                                <TextField className='w-25 my-2' id="bizimh_pass" type="password" label={t("BizimHesap Password")} variant="outlined" onChange={(e) => setpassWord(e.target.value)}/>

                                <p className='w-25 my-2'> {t("Select Invoice Type")} </p>
                                <RadioGroup className='bizim-class w-25 my-2' aria-label="bizimhesap_radio" name="bizimhesap_radio" value={invoiceType} onChange={(e) => setInvoiceType(e.target.value)}>
                                    <FormControlLabel value={t("alis")} control={<Radio />} label={t("Purchase")}  />
                                    <FormControlLabel value={t("satis")} control={<Radio />} label={t("Sales")} />
                                    <FormControlLabel value={t("tum_faturalar")} control={<Radio />} label={t("Both (Purchase and Sales)")} />

                                </RadioGroup>
                            </div>
                        }

                        {integ === "5" &&
                            <div className='profile-dialog-body'>
                                <TextField className='w-25 my-2' id="parasut_username" type="text" label={t("Paraşüt User Name")} variant="outlined" onChange={(e) => setuserName(e.target.value)}/>
                                <TextField className='w-25 my-2' id="parasut_pass" type="password" label={t("Paraşüt Password")} variant="outlined" onChange={(e) => setpassWord(e.target.value)}/>
                                <TextField className='w-25 my-2' id="parasut_api" type="text" label={t("Paraşüt API Key")} variant="outlined" onChange={(e) => setapiKey(e.target.value)}/>
                                <TextField className='w-25 my-2' id="parasut_secret" type="text" label={t("Paraşüt Secret Key")} variant="outlined" onChange={(e) => setsecretKey(e.target.value)}/>
                            </div>
                        }

                        {integ === "6" &&
                            <div className='profile-dialog-body'>
                                <TextField className='w-25 my-2' id="edm_username" type="text" label={t("EDM User Name")} variant="outlined" onChange={(e) => setuserName(e.target.value)}/>
                                <TextField className='w-25 my-2' id="edm_pass" type="text" label={t("EDM Password")} variant="outlined" onChange={(e) => setpassWord(e.target.value)}/>
                                <TextField className='w-25 my-2' id="edm_api" type="text" label={t("EDM API Key")} variant="outlined" onChange={(e) => setapiKey(e.target.value)}/>
                                <TextField className='w-25 my-2' id="edm_secret" type="text" label={t("EDM Secret Key")} variant="outlined" onChange={(e) => setsecretKey(e.target.value)}/>
                            </div>
                        }

                    </Dialog>
                </Components.Container>
            </div>
      )
    }
}

export default FirstLogin;