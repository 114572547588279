import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from "react-i18next";

import { Image } from 'react-native';
import SeasonalityImg from "../assets/img/season.png";

import { addThousandSeperator } from "../components/ThousandSep";

export default function PurchaseSeasonalityTrendAnomaly({ hvalue="100%" }) {

  const [labels, setLabel] = useState(null);
  const [trend, setTrend] = useState(null);
  const [residue, setResidue] = useState(null);
  const [seasonal, setSeasonal] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;

  useEffect(() => {

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gider/"
      }
    }
  
    fetch(process.env.REACT_APP_REQ_IP + '/purchase_seasonality_trend_anomaly', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.results) {
        const label = JSON.parse(data.label);
        const seasonal = JSON.parse(data['1chart']);
        const residue = JSON.parse(data['2chart']);
        const trend = JSON.parse(data['3chart']);
  
        // string formatter
        // var formatter = new Intl.NumberFormat("de-DE");
        // seasonal[i] = formatter.format(element);
            
        setLabel(label);
        setResidue(residue);
        setSeasonal(seasonal);
        setTrend(trend);
      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
        console.log(err)
    });

  }, [firmid, integration]);


  return (
    <>
      {labels === null && seasonal === null  && trend === null && serverMsg !== null &&
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>{t(serverMsg)}</span>
        </div>
      }
      {labels !== null && seasonal !== null  && trend !== null && serverMsg === null &&
      <Chart
        height={hvalue}
          options={{
            chart: {
              type: 'line',
              stacked: false,
              zoom: {
                autoScaleYaxis: true,
                autoScaleXaxis: true
              },
              animations: {
                enabled: false
              },
              parentHeightOffset: 0,
              background: themeBack
            },
            theme: {
              mode: themeMode, 
          },    
            markers: {
              size: [6, 3, 0],
              strokeColors: '#fff',
              strokeWidth: 2,
              hover: {
                size: undefined,
                sizeOffset: 3
              }
            },
            colors: ['#0064ff', '#33cd32', '#ff7e0e'],
            labels: labels,
            xaxis: {
              type: 'datetime',
              labels: {
                format: 'yyyy/MM/dd'
              },
              tickAmount: 15
            },     
            yaxis: {
              title: {
                text: t('Seasonality & Trend & Residue Analysis'),
              },
              labels: {
                formatter: function(value) {
                  if(value !== null){
                    return addThousandSeperator(value);
                  }
                }
              },
            },
          }}

          series={[
            {
              name: t('Residue'),
              type: 'scatter',
              data: residue
            },
            {
              name: t('Seasonality'),
              type: 'line',
              data: seasonal
            },
            {
              name: t('Trend'),
              type: 'line',
              data: trend
            }
          ]}
        />
      }
    </>

  );
}
