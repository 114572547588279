import React, { useState, useEffect } from 'react';

import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useTranslation } from "react-i18next";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { useTheme } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Profile() {

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const firmid = window.sessionStorage.getItem("comp_id");
    const email = window.sessionStorage.getItem("email");
    const fname = window.sessionStorage.getItem("fname");
    const lname = window.sessionStorage.getItem("lname");
    const address = window.sessionStorage.getItem("address");
    const company = window.sessionStorage.getItem("company");
    const vkn = window.sessionStorage.getItem("tax_no");
    const tax_address = window.sessionStorage.getItem("tax_address");
    const integration = window.sessionStorage.getItem("integration");

    const [integ, setInteg] = useState(integration);
    const [open, setOpen] = useState(false);
    const [btnstatus, setBtnstatus] = useState(true);

    const [uploadFile, setUploadFile] = useState();
    const [uploadFile2, setUploadFile2] = useState();

    const [apiKey, setapiKey] = useState('');
    const [secretKey, setsecretKey] = useState('');
    const [userName, setuserName] = useState('');
    const [passWord, setpassWord] = useState('');
    const [invoiceType, setInvoiceType] = useState('satis');

    const { t, i18n } = useTranslation();

    let navigate = useNavigate();

    const integrationChange = event => {

        const value  = event.target.value;
        setInteg(value);

        setuserName('');
        setpassWord('');
        setapiKey('');
        setsecretKey('');
        setUploadFile();
        setUploadFile2();

        if(value !== 1) {
            setOpen(true);
        }
        else if(value === 1) {
            updateIntegrationType(1);
        }
    };

    var pack = window.sessionStorage.getItem("pack")

    const opts = {
        1: t("Show demo data"),
        2: t("Excel file upload"),
        3: t("Upload e-invoices (.zip of xml files)"),
        4: t("BizimHesap integration"),
        5: t("Paraşüt integration"),
        6: t("EDM integration"),
        7: t("QNB Finansbank integration")

    }

    function updateIntegrationType(type) {
        const list_obj = {
            'firmid': firmid,
            'isFirstLogin': type
        }

        fetch(process.env.REACT_APP_REQ_IP + '/update_isfirst_login', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {

            setIsLoading(false);

            if(data.result === true) {

                enqueueSnackbar(t(data.message), { 
                    variant: 'success',
                    persist: false
                });

                setInteg(type);
                window.sessionStorage.setItem("integration", type);
                
                if(pack == "free") {
                    navigate("/sandbox", { replace: true });
                }
                else {
                    navigate("/app", { replace: true });
                }
            }
            else {
                enqueueSnackbar(t(data.message), { 
                    variant: 'error',
                    persist: false
                });
            }

        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }

    const handleSaveInteg = () => {

        setIsLoading(true);

        if(integ === 2 || integ === 3) {

            const formData = new FormData()
            formData.append('files', uploadFile)
            formData.append('files', uploadFile2)

            fetch(process.env.REACT_APP_REQ_IP + '/upload_file?firmid=' + firmid + '&integrationType=' + String(integ) + '&isFirstLogin=' + String(integ), { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/zip'}, body: formData}).then(res => res.json()).then(data => {

                setIsLoading(false);

                if(data.result) {

                    enqueueSnackbar(t(data.message), { 
                        variant: 'success',
                        persist: false
                    });

                    updateIntegrationType(integ);
                }
                else {

                    enqueueSnackbar(t(data.message), { 
                        variant: 'error',
                        persist: false
                    });
                }
            })
            .catch(err => {
                setIsLoading(false);
                setError(err);
            });    
        }
        else {

            const integ_list = {
                4: "bizimhesap",
                5: "paraşüt",
                6: "edm",
                7: "qnb"
            }

            const update_list_obj = {
                "firmid": firmid,
                "isFirstLogin": String(integ),
                "integrationType": integ,
                "app_api_key": apiKey,
                "app_secret_key": secretKey,
                "app_username": userName,
                "app_passw": passWord,
                "app_name": integ_list[integ],
                "app_invoice_type": invoiceType
            }

            fetch(process.env.REACT_APP_REQ_IP + '/update_account_info', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(update_list_obj)}).then(res => res.json()).then(data => {

                setIsLoading(false);

                if(data.result === true) {

                    enqueueSnackbar(t(data.message), { 
                        variant: 'success',
                        persist: false
                    });

                    setInteg(integ);
                    window.sessionStorage.setItem("integration", integ);

                }
                else {
                    enqueueSnackbar(t(data.message), { 
                        variant: 'error',
                        persist: false
                    });
                }

            })
            .catch(err => {
                setIsLoading(false);
                setError(err);
            });

        }

        setOpen(false);
    };

    useEffect(() => {

        if(userName.length<2 && passWord.length<2) {
            setBtnstatus(false);
        }
        else {
            setBtnstatus(true);
        }
    
    }, [apiKey, secretKey, userName, passWord]);
    
    const handleClose = () => {
        setOpen(false);

        setInteg(integration);
    };

    const handleFile = (event) => {
        const file_size = event.target?.files[0]?.size;

        if(file_size) {
            const file_size_mb = (file_size / (1024*1024));
            if(file_size_mb > 1000) {
                enqueueSnackbar(t("Your file must be under 1 GB. Please upload a smaller .zip file."), { 
                    variant: 'error',
                    persist: false
                });
    
                setBtnstatus(false);
            }
            else {
                enqueueSnackbar(t("File successfully uploaded. Dont forget to save."), { 
                    variant: 'info',
                    persist: false
                });

                if(event.target.id === "data_xlsx" || event.target.id === "data_zip") {
                    setUploadFile(event.target.files[0]);
                }
                else if(event.target.id === "data_xlsx_spending" || event.target.id === "data_zip_spending") {
                    setUploadFile2(event.target.files[0]);
                }
                else {
                    console.log("Dosya tipi seçenekler arasında mevcut değil.")
                }

                setBtnstatus(true);
            }
        }

	};

    const updateProfile=(e) => { 

        e.preventDefault();
        setIsLoading(true);

        const user_lname2 = document.getElementById('user_lname').value;
        const user_fname2 = document.getElementById('user_fname').value;
        const user_address2 = document.getElementById('user_address').value;
        const user_company2 = document.getElementById('user_company').value;
        const user_vkn2 = document.getElementById('user_vkn').value;
        const user_tax_add2 = document.getElementById('user_tax_add').value;

        const update_list_obj = {
            "user_fname": user_fname2,
            "user_lname": user_lname2,
            "user_address": user_address2,
            "user_company": user_company2,
            "user_vkn": user_vkn2,
            "user_tax_add": user_tax_add2,
            "user_email": email
        }

        fetch(process.env.REACT_APP_REQ_IP + '/update_user', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(update_list_obj)}).then(res => res.json()).then(data => {

            setIsLoading(false);

            if(data.result === true) {
                window.sessionStorage.setItem("fname", user_fname2);
                window.sessionStorage.setItem("lname", user_lname2);
                window.sessionStorage.setItem("address", user_address2);
                window.sessionStorage.setItem("company", user_company2);
                window.sessionStorage.setItem("tax_no", user_vkn2);
                window.sessionStorage.setItem("tax_address", user_tax_add2);

                enqueueSnackbar(t(data.message), { 
                    variant: 'success',
                    persist: false
                });

            }
            else {
                enqueueSnackbar(t(data.message), { 
                    variant: 'error',
                    persist: false
                });
            }

        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }
    

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#d83534" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="container-fluid">
                <div className="row">
                    <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                    <div className="col-md-8 h-auto">
                        <Card className="profile-container h-100">
                            <CardHeader title={t("Profile")}></CardHeader>
                            
                            <div className="card-body">
                                <form method="POST" onSubmit={updateProfile}>
                                    <div className="row">
                                         <div className="col-md-12">
                                            <TextField className='w-100 mb-2' id="user_email" type="email" label={t("Email")}variant="outlined" value={email} disabled />
                                        </div> 
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TextField className='w-100 my-2' id="user_fname" type="text" label={t("First Name")} variant="outlined" defaultValue={fname} required/>
                                        </div>
    
                                        <div className="col-md-6">
                                            <TextField className='w-100 my-2' id="user_lname" type="text" label={t("Last Name")} variant="outlined" defaultValue={lname} required/>
                                        </div>
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TextField className='w-100 my-2' id="user_address" type="text" label={t("Address")} variant="outlined" defaultValue={address} required/>
                                        </div>
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TextField className='w-100 my-2' id="user_company" type="text" label={t("Company Name")} variant="outlined" defaultValue={company} required/>
                                        </div>
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TextField className='w-100 my-2' id="user_vkn" type="text" label={t("Tax Number")} variant="outlined" defaultValue={vkn} />
                                        </div>
                                        <div className="col-md-6">
                                            <TextField className='w-100 my-2' id="user_tax_add" type="text" label={t("Tax Address")} variant="outlined" defaultValue={tax_address} />
                                        </div>
                                    </div>
    
                                    <input type="submit" value={t("Save")} className="btn profile-save-btn d-flex ml-auto"/>                                  
    
                                    <div className="clearfix"></div>
                                </form>
                            </div>
    
                        </Card>
    
                    </div>
            
    
                    <div className="col-md-4">
                        <Card className="card-user">
                            <div className="card-image">
                                <img src="/side-1.png" alt="profile-background"/>
                            </div>
                            <div className="card-body">
                                <div className="author">
                                    <a className='profile-title-anc'>
                                        <img className="avatar border-gray" src="/sd.png" alt="..."/>
                                        
                                        <h5 className="profile-title">{fname} {lname}</h5>

                                        <Chip
                                            icon={<DoneIcon />}
                                            label={company}
                                            color="primary"
                                            style={{backgroundColor:'#d83534'}}
                                        />
                                    </a>
                                </div>
                            </div>
                        </Card>

                        <Card className="card-integration">
                            
                            <InputLabel style={{ fontSize: '0.8em', margin: '1em 0em', fontWeight: 600 }}>{t("Only your last selection will be valid.")}</InputLabel>

                            <FormControl variant="outlined" className='w-100 my-2' required>
                                <InputLabel id="select-ids">{t("Integration Type")}</InputLabel>
                                <Select
                                labelId="select-ids"
                                id="integration_type"
                                value={integ}
                                onChange={integrationChange}
                                label={t("Integration Type")}>
                                    <MenuItem value={1}>{t("Show demo data")}</MenuItem>
                                    <MenuItem value={2}>{t("Excel file upload")}</MenuItem>
                                    <MenuItem value={3}>{t("Upload e-invoices (.zip of xml files)")}</MenuItem>
                                    <MenuItem value={4}>{t("BizimHesap integration")} </MenuItem>
                                    <MenuItem value={5}>{t("Paraşüt integration")} </MenuItem>
                                    <MenuItem value={6} disabled>{t("EDM integration")} 
                                        <sup className="text-success">{t("Call Us")}</sup>                                    
                                    </MenuItem>
                                    <MenuItem value={7} disabled>{t("QNB Finansbank integration")} 
                                        <sup className="text-success">{t("Call Us")}</sup>                                    
                                    </MenuItem>
                                </Select>
                            </FormControl>

                            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                                    <AppBar className="profile-dialog-bar">
                                        <Toolbar>
                                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                                <CloseIcon />
                                            </IconButton>
                                            <Typography variant="h6" className="d-flex ml-2">
                                                {opts[integ]}
                                            </Typography>
    
                                            {btnstatus &&
                                                <Button className='profile-dialog-bar-btn' type="button" onClick={handleSaveInteg}>
                                                    {t('Save')}
                                                </Button>                                     
                                            }
    
                                            {!btnstatus &&
                                                <Button className='profile-dialog-bar-btn' type="button" onClick={handleSaveInteg} disabled>
                                                    {t('Save')}
                                                </Button>                                     
                                            }
                                        </Toolbar>
                                    </AppBar>
    
                                    {integ === 2 &&
                                        <div className='profile-dialog-body'>
    
                                            <div className='mb-1'>{t("Upload your data as an excel file.")}</div>
                                            <div className='mb-2'> {t("You can download the sample file format by clicking the link.")}</div>
    
                                            <a className='mb-4' href='https://bilkav.com/kolayai_ornek.xlsx'>{t("Sample file link")}</a>
    
                                            <input
                                                onChange={handleFile}
                                                accept=".xlsx"
                                                className="d-none"
                                                id="data_xlsx"
                                                type="file"
                                                filename="income"
                                            />
                                            <label htmlFor="data_xlsx">
                                                <Button variant="contained" className='profile-save-btn mx-1' component="span">
                                                    {t("Income File Upload")}
                                                </Button>
                                            </label>

                                            <input
                                                onChange={handleFile}
                                                accept=".xlsx"
                                                className="d-none"
                                                id="data_xlsx_spending"
                                                type="file"
                                                filename="sending"
                                            />
                                            <label htmlFor="data_xlsx_spending">
                                                <Button variant="contained" className='profile-save-btn mx-1' component="span">
                                                    {t("Spending File Upload")}
                                                </Button>
                                            </label>
    
                                        </div>
                                    }
    
    
                                    {integ === 3 &&
                                        <div className='profile-dialog-body'>
    
                                            <div className='mb-1'>{t("Upload your data as an zip of xml files.")}</div>
                                            <div className='mb-4'> {t("You can download the zip file from your e-fatura panel.")}</div>

                                            <div className='row'>
    
                                                <input
                                                    onChange={handleFile}
                                                    accept=".zip"
                                                    className="d-none"
                                                    id="data_zip"
                                                    type="file"
                                                />
                                                <label htmlFor="data_zip">
                                                    <Button variant="contained" className='profile-save-btn mx-1' component="span">
                                                        {t("Income File Upload")}
                                                    </Button>
                                                </label>


                                                <input
                                                    onChange={handleFile}
                                                    accept=".zip"
                                                    className="d-none"
                                                    id="data_zip_spending"
                                                    type="file"
                                                />
                                                <label htmlFor="data_zip_spending">
                                                    <Button variant="contained" className='profile-save-btn mx-1' component="span">
                                                        {t("Spending File Upload")}
                                                    </Button>
                                                </label>

                                            </div>
    
                                        </div>
                                    }
    
                                    {integ === 4 &&
                                    
                                        <div className='profile-dialog-body'>
                                            <TextField className='w-25 my-2' id="bizimh_username" type="text" label={t("BizimHesap Email")} variant="outlined" onChange={(e) => setuserName(e.target.value)}/>
                                            <TextField className='w-25 my-2' id="bizimh_pass" type="password" label={t("BizimHesap Password")} variant="outlined" onChange={(e) => setpassWord(e.target.value)}/>

                                            <p className='w-25 my-2'> {t("Select Invoice Type")} </p>
                                            <RadioGroup className='bizim-class w-25 my-2' aria-label="bizimhesap_radio" name="bizimhesap_radio" value={invoiceType} onChange={(e) => setInvoiceType(e.target.value)}>
                                                <FormControlLabel value={t("alis")} control={<Radio />} label={t("Purchase")}  />
                                                <FormControlLabel value={t("satis")} control={<Radio />} label={t("Sales")} />
                                                <FormControlLabel value={t("tum_faturalar")} control={<Radio />} label={t("Both (Purchase and Sales)")} />

                                            </RadioGroup>
                                        </div>
                                    }

                                    {integ === 5 &&
                                        <div className='profile-dialog-body'>
                                            <TextField className='w-25 my-2' id="parasut_username" type="text" label={t("Paraşüt User Name")} variant="outlined" onChange={(e) => setuserName(e.target.value)}/>
                                            <TextField className='w-25 my-2' id="parasut_pass" type="password" label={t("Paraşüt Password")} variant="outlined" onChange={(e) => setpassWord(e.target.value)}/>
                                            <TextField className='w-25 my-2' id="parasut_api" type="text" label={t("Paraşüt API Key")} variant="outlined" onChange={(e) => setapiKey(e.target.value)}/>
                                            <TextField className='w-25 my-2' id="parasut_secret" type="text" label={t("Paraşüt Secret Key")} variant="outlined" onChange={(e) => setsecretKey(e.target.value)}/>
                                        </div>
                                    }

    
                                    {/* {integ === 6 &&
                                        <div className='profile-dialog-body'>
                                            <TextField className='w-25 my-2' id="edm_username" type="text" label={t("EDM User Name")} variant="outlined" onChange={(e) => setuserName(e.target.value)}/>
                                            <TextField className='w-25 my-2' id="edm_pass" type="password" label={t("EDM Password")} variant="outlined" onChange={(e) => setpassWord(e.target.value)}/>
                                            <TextField className='w-25 my-2' id="edm_api" type="text" label={t("EDM API Key")} variant="outlined" onChange={(e) => setapiKey(e.target.value)}/>
                                            <TextField className='w-25 my-2' id="edm_secret" type="text" label={t("EDM Secret Key")} variant="outlined" onChange={(e) => setsecretKey(e.target.value)}/>
                                        </div>
                                    } */}

                                    {/* {integ === 7 &&
                                        <div className='profile-dialog-body'>
                                            <TextField className='w-25 my-2' id="qnb_username" type="text" label={t("QNB Finansbank User Code")} variant="outlined" onChange={(e) => setuserName(e.target.value)}/>
                                            <TextField className='w-25 my-2' id="qnb_pass" type="password" label={t("QNB Finansbank Password")} variant="outlined" onChange={(e) => setpassWord(e.target.value)}/>
                                            
                                            <p className='w-25 my-3'> {t("Select Invoice Type")} </p>
                                            <RadioGroup className='bizim-class' aria-label="qnb_radio" name="qnb_radio" value={invoiceType} onChange={(e) => setInvoiceType(e.target.value)}>
                                                <FormControlLabel value={t("alis")} control={<Radio />} label={t("Purchase")}  />
                                                <FormControlLabel value={t("satis")} control={<Radio />} label={t("Sales")} />
                                            </RadioGroup>

                                            <p className='w-25 my-3'> {t("Select Invoice Status")} </p>
                                            <RadioGroup className='bizim-class' aria-label="qnb_type_radio" name="qnb_type_radio" value={invoiceStatus} onChange={(e) => setInvoiceStatus(e.target.value)}>
                                                <FormControlLabel value={t("all")} control={<Radio />} label={t("All")}  />
                                                <FormControlLabel value={t("pending")} control={<Radio />} label={t("Pending approval & Accepted")} />
                                                <FormControlLabel value={t("accepted")} control={<Radio />} label={t("Accepted")} />

                                            </RadioGroup>
                                        </div>
                                    } */}
    
                                </Dialog>

                        </Card>
                    </div>
                </div>
            </div>
    
        );
    }


}

export default Profile;
