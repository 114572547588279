import React from 'react';
import { Image } from 'react-native';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import SalesSeasonalityTrendAnomaly from "../../charts/sales_seasonality_trend_anomaly";
import SalesPredAmountWeekly from "../../charts/sales_prediction_amount_weekly";
import SalesPredAmountMonthly from "../../charts/sales_prediction_amount_monthly";
import SalesPredCountMonthly from "../../charts/sales_prediction_count_monthly";
import SalesPredCountWeekly from "../../charts/sales_prediction_count_weekly";
import SalesPredAmountEndofYear from "../../charts/sales_prediction_amount_endofyear";
import SalesPredAmountNext12M from "../../charts/sales_prediction_amount_next12m"
import SalesPredCountNext12M from "../../charts/sales_prediction_count_next12m";
import SalesPredCountEndofYear from "../../charts/sales_prediction_count_endofyear";

import SeasonalityImg from "../../assets/img/season.png";

export default function IncomePred(packageType, msg="") {

  const { t, i18n } = useTranslation();

  return (   
    <> 
      <div className='d-flex w-100'>
        <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Sales Amount Prediction (Until the End of Year)")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <SalesPredAmountEndofYear/> 
          </Card>


        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Sales Amount Prediction (Next 12 Months)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <SalesPredAmountNext12M/>
        </Card>
      </div>

      <div className='d-flex w-100'>
        <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Sales Count Prediction (Until the End of Year)")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <SalesPredCountEndofYear/> 
          </Card>


        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Sales Count Prediction (Next 12 Months)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <SalesPredCountNext12M/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Sales Amount Prediction (Weekly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <SalesPredAmountWeekly hvalue="250%"/>
        </Card>
      </div>

      <div className='d-flex'>

      <Card className="pages-container">
        <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
          <Typography variant="caption" className="bar-title" gutterBottom>
          {t("Sales Amount Prediction (Monthly)")}
          </Typography>
          <div className={{ flexGrow: 1 }} />
        </div>
        <SalesPredAmountMonthly hvalue="250%"/>
      </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Sales Count Prediction (Monthly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <SalesPredCountMonthly hvalue="250%"/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Sales Count Prediction (Weekly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <SalesPredCountWeekly hvalue="250%" />
        </Card>
      </div>

      { packageType === "premium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Sales Seasonality Trend Anomaly")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <SalesSeasonalityTrendAnomaly hvalue="250%"/>
          </Card>
        </div> 
      }

      {packageType === "freemium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
              {t("Sales Seasonality Trend Anomaly")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>

            <div className="blur-container"> 
              <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
              <a type='button' className="btn" href='/packages'>{t(msg)}</a>
            </div>

          </Card>
        </div>
      
      }
      
    </>
  );
}
