export default {
    "lg": [
        {
            "w": 3,
            "h": 2,
            "x": 3,
            "y": 0,
            "i": "1",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "h": 6,
            "x": 0,
            "y": 2,
            "i": "2",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "h": 6,
            "x": 6,
            "y": 2,
            "i": "3",
            "moved": false,
            "static": false
        },
        {
            "w": 12,
            "h": 6,
            "x": 0,
            "y": 8,
            "i": "4",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "h": 5,
            "x": 0,
            "y": 14,
            "i": "5",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "h": 5,
            "x": 4,
            "y": 14,
            "i": "6",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 2,
            "x": 6,
            "y": 0,
            "i": "7",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 2,
            "x": 0,
            "y": 0,
            "i": "8",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 2,
            "x": 9,
            "y": 0,
            "i": "9",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "h": 5,
            "x": 8,
            "y": 14,
            "i": "10",
            "moved": false,
            "static": false
        }
    ],
    "xs": [
        {
            "w": 2,
            "h": 2,
            "x": 2,
            "y": 0,
            "i": "1",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "h": 5,
            "x": 0,
            "y": 4,
            "i": "2",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 5,
            "x": 2,
            "y": 9,
            "i": "3",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 5,
            "x": 0,
            "y": 9,
            "i": "4",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "h": 5,
            "x": 0,
            "y": 14,
            "i": "5",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "h": 5,
            "x": 0,
            "y": 19,
            "i": "6",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 2,
            "x": 0,
            "y": 0,
            "i": "7",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 2,
            "x": 2,
            "y": 2,
            "i": "8",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 2,
            "x": 0,
            "y": 2,
            "i": "9",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "h": 5,
            "x": 0,
            "y": 24,
            "i": "10",
            "moved": false,
            "static": false
        }
    ],
    "sm": [
        {
            "w": 3,
            "h": 2,
            "x": 3,
            "y": 0,
            "i": "1",
            "moved": false,
            "static": false
        },
        {
            "w": 6,
            "h": 6,
            "x": 0,
            "y": 4,
            "i": "2",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 5,
            "x": 3,
            "y": 10,
            "i": "3",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 5,
            "x": 0,
            "y": 10,
            "i": "4",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 5,
            "x": 0,
            "y": 15,
            "i": "5",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 5,
            "x": 3,
            "y": 15,
            "i": "6",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 2,
            "x": 0,
            "y": 0,
            "i": "7",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 2,
            "x": 3,
            "y": 2,
            "i": "8",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 2,
            "x": 0,
            "y": 2,
            "i": "9",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 6,
            "x": 0,
            "y": 20,
            "i": "10",
            "moved": false,
            "static": false
        }
    ],
    "md": [
        {
            "w": 3,
            "h": 2,
            "x": 0,
            "y": 0,
            "i": "1",
            "moved": false,
            "static": false
        },
        {
            "w": 7,
            "h": 5,
            "x": 3,
            "y": 2,
            "i": "2",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 5,
            "x": 0,
            "y": 2,
            "i": "3",
            "moved": false,
            "static": false
        },
        {
            "w": 10,
            "h": 6,
            "x": 0,
            "y": 7,
            "i": "4",
            "moved": false,
            "static": false
        },
        {
            "w": 5,
            "h": 5,
            "x": 0,
            "y": 13,
            "i": "5",
            "moved": false,
            "static": false
        },
        {
            "w": 5,
            "h": 5,
            "x": 5,
            "y": 13,
            "i": "6",
            "moved": false,
            "static": false
        },
        {
            "w": 4,
            "h": 2,
            "x": 6,
            "y": 0,
            "i": "7",
            "moved": false,
            "static": false
        },
        {
            "w": 3,
            "h": 2,
            "x": 3,
            "y": 0,
            "i": "8",
            "moved": false,
            "static": false
        },
        {
            "w": 5,
            "h": 2,
            "x": 5,
            "y": 18,
            "i": "9",
            "moved": false,
            "static": false
        },
        {
            "w": 5,
            "h": 6,
            "x": 0,
            "y": 18,
            "i": "10",
            "moved": false,
            "static": false
        }
    ],
    "xxs": [
        {
            "w": 2,
            "h": 2,
            "x": 0,
            "y": 0,
            "i": "1",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 5,
            "x": 0,
            "y": 4,
            "i": "2",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 5,
            "x": 0,
            "y": 17,
            "i": "3",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 4,
            "x": 0,
            "y": 9,
            "i": "4",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 5,
            "x": 0,
            "y": 22,
            "i": "5",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 5,
            "x": 0,
            "y": 27,
            "i": "6",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 2,
            "x": 0,
            "y": 2,
            "i": "7",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 2,
            "x": 0,
            "y": 13,
            "i": "8",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 2,
            "x": 0,
            "y": 15,
            "i": "9",
            "moved": false,
            "static": false
        },
        {
            "w": 2,
            "h": 6,
            "x": 0,
            "y": 32,
            "i": "10",
            "moved": false,
            "static": false
        }
    ]
}