import React from "react";
import Card from "@material-ui/core/Card";

import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import AddList from "./AddList";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export default function TopBar({ onLayoutSave, items, onRemoveItem, onAddItem, originalItems, toogleOpenMode, openMode }) {

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  return (
    <Card className={classes.root}>
      <p className="warn-title"> <AutorenewIcon className="mr-1"/> <i>{t("If you have chosen integration, your data will be updated after the analysis is finished, depending on your package selection.")}</i></p>
      <AddList
        items={items}
        onRemoveItem={onRemoveItem}
        onAddItem={onAddItem}
        originalItems={originalItems}/>
      <IconButton color="inherit" aria-label="save" onClick={onLayoutSave}>
        <SaveIcon />
      </IconButton>

      <IconButton
          color="inherit"
          aria-label="lock"
          onClick={toogleOpenMode}
          edge="start">
          {openMode ? <LockIcon /> : <LockOpenIcon />}
        </IconButton>

    </Card>
  );
}
