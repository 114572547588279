import React, { useState, useEffect } from 'react';

import { Text, View, ActivityIndicator } from 'react-native';

import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';

import { useTranslation } from "react-i18next";

import validator from 'validator';

import { DataGrid } from '@mui/x-data-grid';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const bcrypt = require('react-native-bcrypt');

function UserAdd() {

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [results, setResults] = useState();
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [passError, setPassError] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);

    const firmid = window.sessionStorage.getItem("comp_id");
    const uid = window.sessionStorage.getItem("uid");
    const role = window.sessionStorage.getItem("role");

    const { t, i18n } = useTranslation();

    const columns = [
        { 
            field: 'email', 
            headerName: t('Email'),
            width: 200
        },
        {
            field: 'name',
            headerName: t('First Name'),
            width: 150,
        },
        {
            field: 'lastName',
            headerName: t('Last Name'),
            width: 150,
        },
        {
            field: 'fdate',
            headerName: t('Registration Date'),
            type: 'date',
            width: 200,
        },
        {
            field: 'company',
            headerName: t('Company Name'),
            width: 250,
        }
    ];

    useEffect(() => {

        const list_obj = {
            "firmid": firmid,
            "uid": uid
        }

        fetch(process.env.REACT_APP_REQ_IP + '/company_user_list', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);
            if(data.result === true){

                const res = JSON.parse(data.firm_users);

                res.map((item, index) => {

                    var d = new Date(item.registrationDate);

                    item.fdate = d
                    item.id = index
                })
                setResults(res);
            }
            else {
                enqueueSnackbar(t(data.message), { 
                    variant: 'error',
                    persist: false
                });
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    
    }, [uid, isLoading]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const validateEmail = (e) => {
        var email = e.target.value;

        if (validator.isEmail(email)) {
          setEmailError(true);
        } 
        else {
          setEmailError('Please enter an valid e-mail!');
        }
    }

    const validateName = (e) => {
        var fname = e.target.value;

        if (fname.length>1) {
            setNameError(true);
        } 
        else {
            setNameError('Please enter an valid name!');
        }
    }

    const validateSurName = (e) => {
        var lname = e.target.value;

        if (lname.length>1) {
            setSurnameError(true);
        } 
        else {
            setSurnameError('Please enter an valid last name!');
        }
    }

    const validatePass = (e) => {
        var pass = e.target.value;

        if (pass.length>=6) {
            setPassError(true);
        } 
        else {
            setPassError('Your password must be at least 6 characters!');
        }
    }

    function handleAddUser() {

        setDialogOpen(false);

        const email = document.getElementById('email').value;
        const name = document.getElementById('name').value;
        const surname = document.getElementById('surname').value;
        const company = window.sessionStorage.getItem("company");
        const adPassw = document.getElementById('adPassw').value;

        var salt = bcrypt.genSaltSync(10);
        var hashedPass = bcrypt.hashSync(adPassw, salt);

        const list_obj = {
            "uid": uid,
            "firmid": firmid,
            "email": email,
            "name": name,
            "surname": surname,
            "company": company,
            "passw": hashedPass
        }

        if(emailError === true && nameError === true && surnameError === true && passError === true) {
            setIsLoading(true);

            fetch(process.env.REACT_APP_REQ_IP + '/add_user_byadmin', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
                setIsLoading(false);
                
                if(data.result === false) {
                  enqueueSnackbar(t(data.message), { 
                    variant: 'error',
                    persist: false
                  });
                }
                
                else {
                    enqueueSnackbar(t(data.message), { 
                        variant: 'success',
                        persist: false
                    });
                }
            })
            .catch(err => {
                setIsLoading(false);
                setError(err);
            });
        }
        else {
            enqueueSnackbar(t("Please enter the required information."), { 
                variant: 'error',
                persist: false
            });
        }

    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#d83534" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="container-fluid">
                <div className="row">
                    <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                    <div className="col-md-12 h-auto">
                        <Card className="profile-container h-100">
                            
                            <div className='col-md-12 d-flex justify-content-between'>
                                <CardHeader title={t("Company Users")}></CardHeader>
                                {role === "firmAdmin" && 
                                    <input type="button" value={t("Add Company User")} className="btn profile-save-btn list-title-btn" onClick={handleDialogOpen}/>
                                }
                                {role !== "firmAdmin" &&
                                    <input type="button" value={t("Add Company User")} className="btn profile-save-btn list-title-btn" disabled/>
                                }
                            </div>
                            
                            <div className="mt-4">
                                {results && columns &&
                                    <DataGrid
                                    className="datagrid-container"
                                    rows={results}
                                    columns={columns}
                                    pageSize={5}
                                    />
                                }
                            </div>

                            <Dialog
                                open={dialogOpen}
                                onClose={handleDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">

                                <DialogTitle id="alert-dialog-title">{t("Enter the user information you want to add to your company.")}</DialogTitle>

                                <DialogContent>
                                    <TextField autoFocus id="email" label={t("Email")} type="email" onChange={(e) => validateEmail(e)} fullWidth/>
                                    {emailError !== true &&
                                        <span className="mb-2" style={{ color: 'red', fontSize: '0.7em' }}>{t(emailError)}</span>
                                    }

                                    <TextField id="adPassw" label={t("Password")} type="password" className="mb-2" onChange={(e) => validatePass(e)} fullWidth/>
                                    {passError !== true &&
                                        <span className="mb-2" style={{ color: 'red', fontSize: '0.7em' }}>{t(passError)}</span>
                                    }

                                    <TextField id="name" label={t("First Name")} type="text" className="mb-2" onChange={(e) => validateName(e)} fullWidth/>
                                    {nameError !== true &&
                                        <span className="mb-2" style={{ color: 'red', fontSize: '0.7em' }}>{t(nameError)}</span>
                                    }

                                    <TextField id="surname" label={t("Last Name")} type="text" className="mb-2" onChange={(e) => validateSurName(e)} fullWidth/>
                                    {surnameError !== true &&
                                        <span className="mb-2" style={{ color: 'red', fontSize: '0.7em' }}>{t(surnameError)}</span>
                                    }

                                </DialogContent>

                                <DialogActions>
                                    <Button onClick={handleDialogClose} color="primary" autoFocus>
                                    {t('Cancel')}
                                    </Button>
                                    <Button onClick={handleAddUser} color="primary" >
                                    {t('Add User')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
    
                        </Card>
    
                    </div>
                </div>
            </div>
    
        );
    }


}

export default UserAdd;
