import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';

import { useNavigate } from "react-router-dom";

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import LangSwitch from '../components/LangSwitch';
import { useTranslation } from "react-i18next";

import validator from 'validator';

import * as Components from "../assets/js/auth";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const bcrypt = require('react-native-bcrypt');

function Sign() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //authentication event
    const [emailError, setEmailError] = useState('');
    const [passError, setPassError] = useState('');

    const [signIn, toggle] = useState(true);

    const { t, i18n } = useTranslation();

    let uid = window.sessionStorage.getItem("uid");
    let role = window.sessionStorage.getItem("role");
    let integration = window.sessionStorage.getItem("integration");

    //if user authenticated
    if(uid !== null) {
      if(integration === "0") {
        navigate("/choice", { replace: true }); 
      }
      else if(role === "freemium") {
        navigate("/sandbox", { replace: true });
      }
      else {
        navigate("/app", { replace: true });
      }
    }

    const validateEmail = (e) => {
        var email = e.target.value;

        if (validator.isEmail(email)) {
          setEmailError('Valid email address ✓');
        } 
        else {
          setEmailError('Please enter an valid e-mail!');
        }
    }
    
    const validatePass = (e) => {
        var pass = e.target.value;

        if (pass.length>=6) {
            setPassError('Valid password ✓');
        } 
        else {
            setPassError('Your password must be at least 6 characters!');
        }
    }

    function handleUserRegister() {

        setIsLoading(true);

        const fname = document.getElementById('user_fname').value;
        const lname = document.getElementById('user_lname').value;
        const company = document.getElementById('user_comp').value;

        const company_address = document.getElementById('comp_address').value;
        const tax_no = document.getElementById('tax_no').value;
        const tax_address = document.getElementById('tax_address').value;

        const email = document.getElementById('user_email').value;
        const passw = document.getElementById('user_password').value;

        var salt = bcrypt.genSaltSync(10);
        var hashedPass = bcrypt.hashSync(passw, salt);

        const list_obj = {
            "email": email
        }

        const register_list_obj = {
          "name": fname,
          "lastname": lname,
          "company": company,

          "comp_address": company_address,
          "tax_no": tax_no,
          "tax_address": tax_address,

          "email": email,
          "pass": hashedPass,
          "lang": i18n.language
        }

        fetch(process.env.REACT_APP_REQ_IP + '/users', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
          setIsLoading(false);
          //kullanıcı yoksa
          if(data.result === false) {
            fetch(process.env.REACT_APP_REQ_IP + '/register', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(register_list_obj)}).then(res => res.json()).then(data => {

                //register işlemi başarılıysa
                if(data.result === true) {
                  enqueueSnackbar(t(data.message), { 
                    variant: 'success',
                    persist: false
                  });
                }

                //register işlemi failse
                else{
                  enqueueSnackbar(t(data.message), { 
                    variant: 'error',
                    persist: false
                  });
                }
              })
            }
            //kullanıcı varsa
            else { 
              enqueueSnackbar(t(data.message), { 
                variant: 'info',
                persist: false
              });
          }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }
    
    function handleUserLogin() { 
        setIsLoading(true);

        const email = document.getElementById('user_lemail').value;
        const pass = document.getElementById('user_lpassword').value;

        const list_obj = {
            "email": email,
        }
        
        fetch(process.env.REACT_APP_REQ_IP + '/users', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);
            if(data.result === false) {
              enqueueSnackbar(t(data.message), { 
                variant: 'error',
                persist: false
              });
            }
            else {
                var tempCompare = bcrypt.compareSync(pass, data.userInfo[0][12]); 

                if(tempCompare === true) {

                    window.sessionStorage.setItem("uid", data.userInfo[0][0]);
                    window.sessionStorage.setItem("comp_id", data.userInfo[0][1]);
                    window.sessionStorage.setItem("email", data.userInfo[0][2]);
                    window.sessionStorage.setItem("fname", data.userInfo[0][3]);
                    window.sessionStorage.setItem("lname", data.userInfo[0][4]);
                    window.sessionStorage.setItem("tax_no", data.userInfo[0][5]);
                    window.sessionStorage.setItem("tax_address", data.userInfo[0][6]);
                    window.sessionStorage.setItem("company", data.userInfo[0][7]);
                    window.sessionStorage.setItem("address", data.userInfo[0][8]);
                    window.sessionStorage.setItem("role", data.userInfo[0][13]);

                    const isFirstLogin = data.userInfo[0][11];
                    window.sessionStorage.setItem("integration", isFirstLogin);

                    const pack = data.userInfo[0][10];
                    window.sessionStorage.setItem("pack", pack);

                    if(isFirstLogin === 0) {
                      navigate("/choice", { replace: true }); 
                    }
                    else if(pack === "free") {
                      navigate("/sandbox", { replace: true });
                    }
                    else {
                      navigate("/app", { replace: true });
                    }
                }
                else {
                    enqueueSnackbar(t("Şifreniz yanlış, lütfen tekrar deneyiniz"), { 
                      variant: 'error',
                      persist: false
                    });
                }
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#d83534" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (       
          <div className='login-body'>  
            <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
            <Components.Container>

              <Components.SignUpContainer signingIn={signIn}>
                <form className='login-form h-100' method="POST" onSubmit={handleUserRegister}>
                  <Components.Title className='mb-3 align-self-center'>{t('Create Account')}</Components.Title>

                  <div className='d-flex justify-content-between'>
                    <div>
                      <Components.Input type="text" placeholder={t("First Name")} className="form-control" id="user_fname" required/>
                    </div>
                    <div>
                      <Components.Input type="text" placeholder={t("Last Name")} className="form-control" id="user_lname" required/>
                    </div>
                  </div>

                  <span style={{ color: 'red', fontSize: '0.7em' }}>{t(emailError)}</span>
                  <Components.Input type="email" placeholder={t("Email")}  className="form-control" id="user_email" required onChange={(e) => validateEmail(e)}/>

                  <span style={{ color: 'red', fontSize: '0.7em' }}>{t(passError)}</span>
                  <Components.Input type="password" placeholder={t("Password")} className="form-control" id="user_password" required  onChange={(e) => validatePass(e)}/>

                  <span className='mt-2 align-self-start'>{t("Invoice Details")}</span>

                  <div className='d-flex justify-content-between'>
                    <div>
                    <Components.Input type="text" placeholder={t("Company Name")} className="form-control" id="user_comp" required/>
                    </div>
                    <div>
                    <Components.Input type="text" placeholder={t("Company Address")} className="form-control" id="comp_address"/>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div>
                    <Components.Input type="text" placeholder={t("Tax Number")} className="form-control" id="tax_no"/>
                    </div>
                    <div>
                    <Components.Input type="text" placeholder={t("Tax Address")} className="form-control" id="tax_address"/>
                    </div>
                  </div>
                  <div className="form-group mb-0">
                      <label className='d-flex'>
                      <input className="mr-1" type="checkbox" required/> 
                      <small> 
                          <a className='text-decoration-none' href="https://optiwisdom.com/privacy-policy/"> {t("I accept the terms of legal notice")}.</a>
                          <sup style={{ color: 'red' }}>*</sup></small>    
                      </label>
                  </div>
                  <div className="form-group mb-0">
                      <label className='d-flex'>
                      <input className='mr-1' type="checkbox" required/> 
                      <small> 
                          <a className='text-decoration-none' href="https://optiwisdom.com/eula/"> {t("I accept the end user agreement")}.</a>
                          <sup style={{ color: 'red' }}>*</sup></small>    
                      </label>
                  </div>
                  <Components.Ghost2Button type="submit" className='mt-3 align-self-center'>{t("Sign Up")}</Components.Ghost2Button>
                </form>
              </Components.SignUpContainer>

              <Components.SignInContainer signingIn={signIn}>
                <LangSwitch />
                <form className='login-form' method="POST" onSubmit={handleUserLogin}>
                  <div className='login-sign-title'>
                    <Components.Title className='align-self-center mr-1'>{t("Sign In")} </Components.Title> 
                    <hr className="badge-vert-line mr-1"></hr> 

                    <Components.Anchor className='' href="https://kolay.ai/">
                      <img src="logo.png" alt="logo" className="logo-header" />
                    </Components.Anchor>

                  </div>
                  <Components.Input type="email" placeholder={t("Email")} className="form-control" id="user_lemail" required />
                  <Components.Input type="password" placeholder={t("Password")} className="form-control" id="user_lpassword" required/>
                  <Components.Anchor className='align-self-center' href="/reset">{t("Forgot your password?")}</Components.Anchor>
                  <Components.Ghost2Button className='align-self-center' type="submit">{t("Sign In")}</Components.Ghost2Button>
                </form>
              </Components.SignInContainer>

              <Components.OverlayContainer signingIn={signIn}>
                <Components.Overlay signingIn={signIn}>
                  <Components.LeftOverlayPanel signingIn={signIn}>
                    <Components.Title>{t("Welcome")}!</Components.Title>
                    <Components.Paragraph>
                      {t("Don't forget to explore your company's AI analytics")} ...
                    </Components.Paragraph>
                    <Components.GhostButton onClick={() => toggle(true)}>
                      {t("Sign In")}
                    </Components.GhostButton>
                  </Components.LeftOverlayPanel>
                  <Components.RightOverlayPanel signingIn={signIn}>
                    <Components.Title>{t("Do you want to grow your business with AI")}?</Components.Title>
                    <Components.Paragraph className='d-flex align-items-center'>
                      {t("Discover more")} 
                      <div onClick={() => toggle(false)} className="round">
                          <div id="cta">
                              <span className="arrow primera next "></span>
                              <span className="arrow segunda next "></span>
                          </div>
                      </div>
                      {/* <DoubleArrowIcon /> */}
                    </Components.Paragraph>
                    <Components.GhostButton onClick={() => toggle(false)}>
                      {t("Sign Up")}
                    </Components.GhostButton>
                  </Components.RightOverlayPanel>
                </Components.Overlay>
              </Components.OverlayContainer>

            </Components.Container>
          </div>
        );
    }
  }

export default Sign;