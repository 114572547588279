import React, { useState, useEffect, useRef } from 'react';

import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

function Authentication() {

    // //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [timeO, setTimeO] = useState(null);

    const search = useLocation().search;

    const uid = new URLSearchParams(search).get('uid');
    const ref = new URLSearchParams(search).get('ref');
    const action = new URLSearchParams(search).get('act');

    const { t, i18n } = useTranslation();

    useEffect(() => {

        const list_obj = {
            'uid': uid,
            'ref': ref,
            'act': action
        }

        fetch(process.env.REACT_APP_REQ_IP + '/auth/unverified', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);

            // setTimeout(() => console.log('Hey, Initial timeout!'), 10000)
            // // setTimeO(tmp_timeO);
            
            if(data.result) {
                enqueueSnackbar(t(data.message), { 
                    variant: 'success',
                    persist: false
                  });
            }
            else {
                enqueueSnackbar(t(data.message), { 
                    variant: 'error',
                    persist: false
                });
            }

            
        })
        .catch(err => {
            setError(err);
        });

        // clearTimeout(timeO);
        navigate("/login", { replace: true });

    }, [uid, ref, action]);


    if(error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                <Text style={{ fontSize: 18, color:"white"}}> {t("Error")}: {error}</Text>
            </View>
        );
    }
    else if(isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }
    else {
        return (
            <article style={{ padding: "100px" }}>
                <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                <h1 className='text-center'>Yönlendiriliyorsunuz... 🔄</h1>
            </article>
        );
    }

}

export default Authentication;