import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files
import translationEN from "./locales/en.json";
import translationTR from "./locales/tr.json";

const resources = {
  en: {
    translation: translationEN,
  },
  tr: {
    translation: translationTR,
  },
};

//i18N Initialization
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:"tr", //default language
    keySeparator: false
});

export default i18n;