import React from 'react';
import { Image } from 'react-native';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import ProfitSeasonalityTrendAnomaly from "../../charts/profit_seasonality_trend_anomaly";
import ProfitPredAmountWeekly from "../../charts/profit_prediction_amount_weekly";
import ProfitPredAmountMonthly from "../../charts/profit_prediction_amount_monthly";
import ProfitPredCountMonthly from "../../charts/profit_prediction_count_monthly";
import ProfitPredCountWeekly from "../../charts/profit_prediction_count_weekly";
import ProfitPredAmountEndofYear from "../../charts/profit_prediction_amount_endofyear";
import ProfitPredAmountNext12M from "../../charts/profit_prediction_amount_next12m"
import ProfitPredCountNext12M from "../../charts/profit_prediction_count_next12m";
import ProfitPredCountEndofYear from "../../charts/profit_prediction_count_endofyear";

import SeasonalityImg from "../../assets/img/season.png";

export default function ProfitPred(packageType, msg="") {

  const { t, i18n } = useTranslation();

  return (   
    <> 
      <div className='d-flex w-100'>
        <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Profit Amount Prediction (Until the End of Year)")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <ProfitPredAmountEndofYear/> 
          </Card>


        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Profit Amount Prediction (Next 12 Months)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <ProfitPredAmountNext12M/>
        </Card>
      </div>

      <div className='d-flex w-100'>
        <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Profit Count Prediction (Until the End of Year)")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <ProfitPredCountEndofYear/> 
          </Card>


        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Profit Count Prediction (Next 12 Months)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <ProfitPredCountNext12M/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Profit Amount Prediction (Weekly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <ProfitPredAmountWeekly hvalue="250%"/>
        </Card>
      </div>

      <div className='d-flex'>

      <Card className="pages-container">
        <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
          <Typography variant="caption" className="bar-title" gutterBottom>
          {t("Profit Amount Prediction (Monthly)")}
          </Typography>
          <div className={{ flexGrow: 1 }} />
        </div>
        <ProfitPredAmountMonthly hvalue="250%"/>
      </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Profit Count Prediction (Monthly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <ProfitPredCountMonthly hvalue="250%"/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Profit Count Prediction (Weekly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <ProfitPredCountWeekly hvalue="250%" />
        </Card>
      </div>

      { packageType === "premium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Profit Seasonality Trend Anomaly")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <ProfitSeasonalityTrendAnomaly hvalue="250%"/>
          </Card>
        </div> 
      }

      {packageType === "freemium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
              {t("Profit Seasonality Trend Anomaly")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>

            <div className="blur-container"> 
              <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
              <a type='button' className="btn" href='/packages'>{t(msg)}</a>
            </div>

          </Card>
        </div>
      
      }
      
    </>
  );
}
