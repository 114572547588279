import React, { useState, useEffect } from 'react';

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import { useTheme } from '@material-ui/core/styles';

import CytoscapeComponent from 'react-cytoscapejs';

export default function Top3ProductBySegment({ hvalue="100%" }) {

  const [val, setVal] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  const themeS = useTheme();
  const themeText = themeS.palette.text.primary;

  const layout = {
    name: 'preset',
    animate: true,
    animationDuration: 500,
    avoidOverlap: true
  };

  const styleSheet = [
    {
      'selector': 'node',
      'style': {
          'label': 'data(label)'
      }
  },
  {
      'selector': '[shape *= "square"]',
      'style': {
          'background-color': '#d83534',
          'shape': 'roundrectangle'
      }
  },
  {
      'selector': 'label',
      'style': {
          'content': 'data(label)',
          'color': themeText,
          'background-color': themeText
      }
  },
  {
      'selector': 'edge',
      'style': {
          'curve-style': 'bezier',
          'width': 'data(weight)',
          'label': 'data(label)',
          'line-color': '#a2a2a2',
          'source-arrow-color': '#d83534',
          'source-arrow-shape': 'triangle',
      }
  },
  ];

  useEffect(() => {

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gelir/"
      }
    }
  
    fetch(process.env.REACT_APP_REQ_IP + '/top3_prod_by_segment', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.results) {
        const values = JSON.parse(data['df']);

        if(values.length > 0) {
          values.forEach(element => {
            element.data.label = t(element.data.label)
          });
  
          setVal(values)
        }
        else {
          setServermsg(t("You do not have the relevant data file loaded for the graph to be displayed."));
        }

      }
      else {
        setServermsg(t("You do not have the relevant data file loaded for the graph to be displayed."));
      }

    })
    .catch(err => {
      console.log(err);
      setServermsg(t("You do not have the relevant data file loaded for the graph to be displayed."));

    });

  }, [firmid, integration, t]);


  return (
    <>
      {val === null && serverMsg !== null && 
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>{t(serverMsg)}</span>
        </div>
      }
      {val !== null && layout !== null && styleSheet !== null && serverMsg === null &&
      <div>
        <CytoscapeComponent 
        elements={val} 
        style={ { width: '100%', height: '500px' } } 
        zoomingEnabled={true}
        maxZoom={3}
        minZoom={0.3}
        layout={layout}
        stylesheet={styleSheet}
        />
        </div>
      }
    </>

  );
}