import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from "react-i18next";

import { Image } from 'react-native';
import SeasonalityImg from "../assets/img/season.png";

import { addThousandSeperator } from "../components/ThousandSep";

export default function PurchasePredAmountMonthly({ hvalue="100%" }) {

  const [labels, setLabel] = useState(null);
  const [past, setPast] = useState(null);
  const [pred, setPred] = useState(null);
  const [rangeCh, setRangeCh] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;

  useEffect(() => {

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gider/"
      }
    }
  
    fetch(process.env.REACT_APP_REQ_IP + '/purchase_prediction_amount_monthly', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.results) {
        const label = JSON.parse(data.label);
        const past_v = JSON.parse(data['1chart']);
        const pred_v = JSON.parse(data['2chart']);
        const up_v = JSON.parse(data['3chart']);
        const low_v = JSON.parse(data['4chart']);
  
        const range_chart = []
        const past_chart = []
        const pred_chart = []
  
        label.map((element, index) => {
          if(up_v[index] && low_v[index]) {
            range_chart.push({
              'x': element,
              'y': [up_v[index], low_v[index]]
            });
          }

          if(past_v[index]) {
            past_chart.push({
              'x': element,
              'y': past_v[index]
            });
          }

          if(pred_v[index]) {
            pred_chart.push({
              'x': element,
              'y': pred_v[index]
            });
          }
        });
  
        setLabel(label);
        setPast(past_chart);
        setPred(pred_chart);
        setRangeCh(range_chart);
      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
        console.log(err)
    });

  }, [firmid, integration]);


  return (
    <>
      {labels === null && past === null  && pred === null && rangeCh === null && serverMsg !== null &&
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>{t(serverMsg)}</span>
        </div>
      }
      {labels !== null && past !== null  && pred !== null && rangeCh !== null && serverMsg === null &&
      <Chart
          type="rangeArea"
          height={hvalue}
          options={
            {
              chart: {
                animations: {
                  enabled: false
                },
                stacked: false,
                zoom: {
                  autoScaleYaxis: true,
                  autoScaleXaxis: true
                },
                parentHeightOffset: 0,
                background: themeBack
              },
              theme: {
                mode: themeMode, 
            },          
              colors: ['#33cd32', '#67a3ff', '#0064ff'],
              dataLabels: {
                enabled: false
              },
              fill: {
                opacity: [1, 1, 1]
              },
              stroke: {
                curve: 'straight',
                width: [4, 4, 4]
              },
              legend: {
                show: true,
                customLegendItems: [t('Past'), t('Max-Min Pred'), t('Pred')],
                inverseOrder: true
              },

              xaxis: {
                type: 'datetime',
                labels: {
                  format: 'yyyy/MM/dd'
                },
                tickPlacement: "between"
              },    
              yaxis: {
                title: {
                  text: t('Monthly Sales (TL)'),
                },
                labels: {
                  formatter: function(value) {
                    if(value !== null){
                      return addThousandSeperator(value);
                    }
                  }
                },
              },
            }
          }

          series={
            [ 
              {
                name: 'Past',
                type: "line",
                data: past
              },
              {
                name: 'Pred Range',
                type: "rangeArea",
                data: rangeCh
              },
              {
                name: 'Pred',
                type: "line",
                data: pred
              }
            ]
          }
        />
      }
    </>

  );
}