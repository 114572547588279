import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TimelineIcon from '@material-ui/icons/Timeline';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GroupIcon from '@material-ui/icons/Group';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import BarChartIcon from '@material-ui/icons/BarChart';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/Help';
import CategoryIcon from '@material-ui/icons/Category';
import BusinessIcon from '@material-ui/icons/Business';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import StoreIcon from '@material-ui/icons/Store';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AllOutIcon from '@material-ui/icons/AllOut';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  sideButtonActive: {
      backgroundColor:'#fff !important',
      color: '#d83534 !important',
      borderLeft: "3px solid #d83534"
  },
  sideButton: {
    color: '#d83534 !important',
    display: "flex",
    alignItems: "center",
    border: "none !important",
    textAlign: "left !important",
    padding: "0px !important",
    textTransform: 'capitalize !important',
    textDecoration: 'none !important',
    '&:hover': {
      backgroundColor: "initial !important",
    },
    '&:focus': {
      outline: "none !important",
    },
  },
  
  sideButtonIcon: {
    color: '#d83534 !important'
  }
}));

export default function ({ open, handleDrawerClose, handleSidebar }) {

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [btnActive, setBtnActive] = useState("customDash");

  return (
    <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open} classes={{paper: classes.drawerPaper}}>

      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      
      <Divider />

      <div>
        <List>
          <ListItem className={btnActive == "customDash" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "customDash" ? classes.sideButton : "d-flex align-items-center side-button"} value="customDash" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "customDash" ? classes.sideButtonIcon : ""}>
                <DashboardIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Dashboard")}/>
            </Button>
          </ListItem>

          <ListItem className={btnActive == "chatbot" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "chatbot" ? classes.sideButton : "d-flex align-items-center side-button"} value="chatbot" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "chatbot" ? classes.sideButtonIcon : ""}>
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemText primary={t("ChatGPT'ye Sor")}/>
            </Button>
          </ListItem>
          

          <ListItem className={btnActive == "incomePred" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "incomePred" ? classes.sideButton : "d-flex align-items-center side-button"} value="incomePred" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "incomePred" ? classes.sideButtonIcon : ""}>
                <TrendingUpIcon />
              </ListItemIcon>
              <ListItemText primary={t("Income Predictions")}/>
            </Button>
          </ListItem>

          <ListItem className={btnActive == "spendingPred" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "spendingPred" ? classes.sideButton : "d-flex align-items-center side-button"} value="spendingPred" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "spendingPred" ? classes.sideButtonIcon : ""}>
                <TrendingDownIcon />
              </ListItemIcon>
              <ListItemText primary={t("Spending Predictions")}/>
            </Button>
          </ListItem>

          <ListItem className={btnActive == "profitPred" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "profitPred" ? classes.sideButton : "d-flex align-items-center side-button"} value="profitPred" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "profitPred" ? classes.sideButtonIcon : ""}>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary={t("Profit Predictions")}/>
            </Button>
          </ListItem>

          <ListItem className={btnActive == "salesFinancialExpo" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "salesFinancialExpo" ? classes.sideButton : "d-flex align-items-center side-button"} value="salesFinancialExpo" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "salesFinancialExpo" ? classes.sideButtonIcon : ""}>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary={t("Sales Financial Explanations")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "spendingFinancialExpo" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "spendingFinancialExpo" ? classes.sideButton : "d-flex align-items-center side-button"} value="spendingFinancialExpo" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "spendingFinancialExpo" ? classes.sideButtonIcon : ""}>
                <DonutLargeIcon />
              </ListItemIcon>
              <ListItemText primary={t("Purchase Financial Explanations")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "profitFinancialExpo" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "profitFinancialExpo" ? classes.sideButton : "d-flex align-items-center side-button"} value="profitFinancialExpo" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "profitFinancialExpo" ? classes.sideButtonIcon : ""}>
                <MultilineChartIcon />
              </ListItemIcon>
              <ListItemText primary={t("Profit Financial Explanations")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "custAnalytic" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "custAnalytic" ? classes.sideButton : "d-flex align-items-center side-button"} value="custAnalytic" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "custAnalytic" ? classes.sideButtonIcon : ""}>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={t("Customer Analytics")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "productAnalytic" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "productAnalytic" ? classes.sideButton : "d-flex align-items-center side-button"} value="productAnalytic" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "productAnalytic" ? classes.sideButtonIcon : ""}>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary={t("Product/Service Analytics")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "supplierAnalytic" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "supplierAnalytic" ? classes.sideButton : "d-flex align-items-center side-button"} value="supplierAnalytic" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "supplierAnalytic" ? classes.sideButtonIcon : ""}>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={t("Supplier Analytics")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "expendAnalytic" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "expendAnalytic" ? classes.sideButton : "d-flex align-items-center side-button"} value="expendAnalytic" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "expendAnalytic" ? classes.sideButtonIcon : ""}>
                <ImportExportIcon />
              </ListItemIcon>
              <ListItemText primary={t("Expend Analytics")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "prodSegmentAnalytic" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "prodSegmentAnalytic" ? classes.sideButton : "d-flex align-items-center side-button"} value="prodSegmentAnalytic" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "prodSegmentAnalytic" ? classes.sideButtonIcon : ""}>
                <ShuffleIcon />
              </ListItemIcon>
              <ListItemText primary={t("Product/Segment Analysis")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "expendSegmentAnalytic" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "expendSegmentAnalytic" ? classes.sideButton : "d-flex align-items-center side-button"} value="expendSegmentAnalytic" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "expendSegmentAnalytic" ? classes.sideButtonIcon : ""}>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary={t("Expend/Segment Analysis")} />
            </Button>
          </ListItem>

          {/* 
          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="custAnalExpo" onClick={handleSidebar} disableRipple disabled>
              <ListItemIcon>
                <AccountTreeIcon/>
              </ListItemIcon>

              <ListItemText primary={t("Customer Analytics Explanations")} />
            </Button>
          </ListItem> */}

          <ListItem className={btnActive == "custJourney" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "custJourney" ? classes.sideButton : "d-flex align-items-center side-button"} value="custJourney" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "custJourney" ? classes.sideButtonIcon : ""}>
                <BubbleChartIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Customer Journey")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "suppJourney" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "suppJourney" ? classes.sideButton : "d-flex align-items-center side-button"} value="suppJourney" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "suppJourney" ? classes.sideButtonIcon : ""}>
                <AccountTreeIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Supplier Journey")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "custCards" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "custCards" ? classes.sideButton : "d-flex align-items-center side-button"} value="custCards" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "custCards" ? classes.sideButtonIcon : ""}>
                <PersonIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Customer Cards")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "prodCards" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "prodCards" ? classes.sideButton : "d-flex align-items-center side-button"} value="prodCards" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "prodCards" ? classes.sideButtonIcon : ""}>
                <AddShoppingCartIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Product Cards")} />
            </Button>
          </ListItem>

          <ListItem className={btnActive == "suppCards" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "suppCards" ? classes.sideButton : "d-flex align-items-center side-button"} value="suppCards" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "suppCards" ? classes.sideButtonIcon : ""}>
                <StoreIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Supplier Cards")}/>
            </Button>
          </ListItem>

          <ListItem className={btnActive == "expendCards" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "expendCards" ? classes.sideButton : "d-flex align-items-center side-button"} value="expendCards" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "expendCards" ? classes.sideButtonIcon : ""}>
                <CategoryIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Expend Cards")}/>
            </Button>
        </ListItem>

        <ListItem className={btnActive == "salesperson" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "salesperson" ? classes.sideButton : "d-flex align-items-center side-button"} value="salesperson" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "salesperson" ? classes.sideButtonIcon : ""}>
                <AllOutIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Salesperson Analysis")}/>
            </Button>
        </ListItem>

        <ListItem className={btnActive == "salesPerCards" ? classes.sideButtonActive : null} button>
            <Button className={btnActive == "salesPerCards" ? classes.sideButton : "d-flex align-items-center side-button"} value="salesPerCards" onClick={e => { setBtnActive(e.currentTarget.value); handleSidebar(e); }} disableRipple>
              <ListItemIcon className={btnActive == "salesPerCards" ? classes.sideButtonIcon : ""}>
                <AccountBalanceWalletIcon/>
              </ListItemIcon>
              <ListItemText primary={t("Salesperson Cards")}/>
            </Button>
        </ListItem>

        </List>

        <Divider />

        <List>
            {/* <ListItem button>
              <Button className="d-flex align-items-center side-button" value="kolayaiStatic" onClick={handleSidebar} disableRipple disabled>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={t("Kolay.AI Statistics")} />
              </Button>
            </ListItem> */}


            <ListItem button>
              <Button className="d-flex align-items-center side-button help-side-button" value="help" href="https://optiwisdom.com/tr/iletisim/" disableRipple>
                <ListItemIcon className="side-list-icon">
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={t("Help")} />
              </Button>
            </ListItem>
        </List>

      </div>
    </Drawer>
  );
}
