import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Text, View, ActivityIndicator } from 'react-native';

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

import Content from "./Content";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { handleLogout } from "./components/Logout";

import IncomePrediction from "./pages/sidebarPages/IncomePrediction";
import SpendingPrediction from "./pages/sidebarPages/SpendingPrediction";
import ProfitPrediction from "./pages/sidebarPages/ProfitPrediction";
import SalesFinancialExpo from "./pages/sidebarPages/SalesFinancialExpo";
import PurchaseFinancialExpo from "./pages/sidebarPages/PurchaseFinancialExpo";
import ProfitFinancialExpo from "./pages/sidebarPages/ProfitFinancialExpo";
import CustomerAnalytics from "./pages/sidebarPages/CustomerAnalytics";
import ProductAnalytics from "./pages/sidebarPages/ProductAnalytics";
import SupplierAnalytics from "./pages/sidebarPages/SupplierAnalytics";
import ExpendAnalytics from "./pages/sidebarPages/ExpendAnalytics";
import ProdSegAnalytics from "./pages/sidebarPages/ProdSegAnalytics";
import ExpendSegAnalytics from "./pages/sidebarPages/ExpendSegAnalytics";
import SupplierJourney from "./pages/sidebarPages/SupplierJourney";
import CustomerJourney from "./pages/sidebarPages/CustomerJourney";
import CustomerCards from "./pages/sidebarPages/CustomerCards";
import SupplierCards from "./pages/sidebarPages/SupplierCards";
import SalespersonAnalysis from "./pages/sidebarPages/SalespersonAnalysis";
import SalespersonCards from "./pages/sidebarPages/SalespersonCards";
import ProductCards from "./pages/sidebarPages/ProductCards";
import ExpendCards from "./pages/sidebarPages/ExpendCards";

import Profile from "./pages/Profile";
import UserAdd from "./pages/UserAdd";

import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import "./assets/css/styles.css";
import ChatBotPage from "./pages/sidebarPages/ChatBotPage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    overflow: 'hidden',
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


function Theme() {

  const [open, setOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [SelectedPage, setPage] = useState(IncomePrediction("premium"));
  const [CustomPage, setCustomizable] = useState(true);

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  

  let navigate = useNavigate();
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const uid = window.sessionStorage.getItem("uid");
  const firmid = window.sessionStorage.getItem("comp_id")

  //if user not authenticated
  useEffect(() => {
    if(uid === null) {
      handleLogout();
      navigate("/login", { replace: true });
    }

    else {

      const list_obj = {
        "uid": uid
      }

      setIsLoading(true);
    
      fetch(process.env.REACT_APP_REQ_IP + '/check_role', { method: 'POST', mode: 'cors' , headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json())
        .then(data => {
          setIsLoading(false);


          if(data.isFirstLogin === "0") {
            navigate("/choice", { replace: true }); 
          }
          else if(data.packName === "free") {
            navigate("/sandbox", { replace: true });
          }
          else {
            navigate("/app", { replace: true });
          }
          

      })
      .catch(err => {
        setIsLoading(false);
        console.log("Error :", err)
        navigate("/unauthorized", { replace: true });
      });

    }

    window.sessionStorage.removeItem('spec_msg');
  }, [uid]);


  const pageList = {
    "profile" : Profile("premium"),
    "chatbot" : ChatBotPage("premium"),
    "incomePred": IncomePrediction("premium"),
    "spendingPred": SpendingPrediction("premium"),
    "profitPred": ProfitPrediction("premium"),
    "salesFinancialExpo": SalesFinancialExpo(),
    "spendingFinancialExpo": PurchaseFinancialExpo(),
    "profitFinancialExpo": ProfitFinancialExpo(),
    "custAnalytic": CustomerAnalytics("premium"),
    "productAnalytic": ProductAnalytics("premium"),
    "supplierAnalytic": SupplierAnalytics("premium"),
    "expendAnalytic": ExpendAnalytics("premium"),
    "prodSegmentAnalytic": ProdSegAnalytics("premium"),
    "expendSegmentAnalytic": ExpendSegAnalytics("premium"),
    "suppJourney": SupplierJourney("premium"),
    "custJourney": CustomerJourney("premium"),
    "custCards": CustomerCards("premium"),
    "suppCards": SupplierCards("premium"),
    "salesperson": SalespersonAnalysis("premium"),
    "salesPerCards": SalespersonCards("premium"),
    "prodCards": ProductCards("premium"),
    "expendCards": ExpendCards("premium"),
    "userAdd": UserAdd("premium")
  }

  // kolayaiStatic
  
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  var theme = createTheme({
    palette: {
      type: darkMode ? "dark" : "light"
    }
  });

  theme = responsiveFontSizes(theme);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleSidebar = (e) => {
    const selected_page = e.currentTarget.value;

    if(selected_page === "customDash") {
      setCustomizable(true);
    }
    else {
      setCustomizable(false);

      if(selected_page == 0) {
        const from_header = e.target.getAttribute('name');
        setPage(from_header);
      
      }
      else {
        setPage(selected_page);
      }
    }
  };


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
        </View>
    );
  }
  if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
          </View>
      );
  }
  else {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={5000}   anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        <div className={classes.root}>
          <CssBaseline />
          <Header
            handleDrawerToggle={handleDrawerToggle}
            toggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
            handleSidebar={handleSidebar}
          />
          <Sidebar handleDrawerClose={handleDrawerClose} open={open} handleSidebar={handleSidebar} />
          <main className={clsx(classes.content, { [classes.contentShift]: open })}>
            <div className={classes.drawerHeader} />
            {CustomPage &&
              <Content packageType="premium"/>
            }
            {!CustomPage &&
              <>
                {pageList[SelectedPage]}
              </>
            }      
          </main>
        </div>
      </ThemeProvider>
    );
  }

}

export default Theme;
