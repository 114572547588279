import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem"
  },
  spacer: {
    flexGrow: 1
  }
});

const widgetNames = {
  "1": 'Sales Amount Prediction (Until the End of Year)',
  "2": "Sales Seasonality Trend Anomaly",
  "3": "Sales Amount Prediction (Monthly)",
  "4": "Sales Amount Prediction (Weekly)",
  "5": "Sales Count Prediction (Monthly)",
  "6": "Sales Count Prediction (Weekly)",
  "7": "Sales Amount Prediction (Next 12 Months)",
  "8": "Sales Count Prediction (Next 12 Months)",
  "9": "Sales Count Prediction (Until the End of Year)",
  "10": "Sales Forecast Factor Analysis by Time",

  "11": 'Purchase Amount Prediction (Until the End of Year)',
  "12": "Purchase Seasonality Trend Anomaly",
  "13": "Purchase Amount Prediction (Monthly)",
  "14": "Purchase Amount Prediction (Weekly)",
  "15": "Purchase Count Prediction (Monthly)",
  "16": "Purchase Count Prediction (Weekly)",
  "17": "Purchase Amount Prediction (Next 12 Months)",
  "18": "Purchase Count Prediction (Next 12 Months)",
  "19": "Purchase Count Prediction (Until the End of Year)",
  "20": "Purchase Forecast Factor Analysis by Time",

  "21": 'Profit Amount Prediction (Until the End of Year)',
  "22": "Profit Seasonality Trend Anomaly",
  "23": "Profit Amount Prediction (Monthly)",
  "24": "Profit Amount Prediction (Weekly)",
  "25": "Profit Count Prediction (Monthly)",
  "26": "Profit Count Prediction (Weekly)",
  "27": "Profit Amount Prediction (Next 12 Months)",
  "28": "Profit Count Prediction (Next 12 Months)",
  "29": "Profit Count Prediction (Until the End of Year)",
  "30": "Profit Forecast Factor Analysis by Time",

  "31": 'Expend Segmentation Analysis',
  "32": "Customer Segmentation Analysis",
  "33": "Product Segmentation Analysis",
  "34": "Supplier Segmentation Analysis",

};

var noInteracts = document.getElementsByClassName('userInteract');

[].map.call(noInteracts, function(elem) {
    elem.addEventListener("keydown", function(e) {
        if (e.keyCode !== 9) {
            e.returnValue = false;
            return false;
        }
    }, true);
});

export default function Widget({ id, onRemoveItem, userInteractType, component: Item }) {

  const [interactClass, setInteractClass] = useState("userInteractBody userInteract");

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  useEffect(() => {

    if(userInteractType === true) {
      setInteractClass("userInteractBody userInteract");
    }
    else {
      setInteractClass("userInteractBody");
    }

  }, [userInteractType]);

  return (
    <Card className="root-container">
      <div className={classes.header}>
        <Typography variant="caption" className="bar-title" gutterBottom>
          {t(widgetNames[id])}

        </Typography>
        <div className={classes.spacer} />
        <IconButton aria-label="delete" onClick={() => onRemoveItem(id)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <div className={interactClass}>
        <Item />
      </div>
    </Card>
  );
}