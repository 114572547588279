import React from 'react';
import { Image } from 'react-native';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import PurchaseSeasonalityTrendAnomaly from "../../charts/purchase_seasonality_trend_anomaly";
import PurchasePredAmountWeekly from "../../charts/purchase_prediction_amount_weekly";
import PurchasePredAmountMonthly from "../../charts/purchase_prediction_amount_monthly";
import PurchasePredCountMonthly from "../../charts/purchase_prediction_count_monthly";
import PurchasePredCountWeekly from "../../charts/purchase_prediction_count_weekly";
import PurchasePredAmountEndofYear from "../../charts/purchase_prediction_amount_endofyear";
import PurchasePredAmountNext12M from "../../charts/purchase_prediction_amount_next12m"
import PurchasePredCountNext12M from "../../charts/purchase_prediction_count_next12m";
import PurchasePredCountEndofYear from "../../charts/purchase_prediction_count_endofyear";

import SeasonalityImg from "../../assets/img/season.png";

export default function SpendingPred(packageType, msg="") {

  const { t, i18n } = useTranslation();

  return (   
    <> 
      <div className='d-flex w-100'>
        <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Purchase Amount Prediction (Until the End of Year)")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <PurchasePredAmountEndofYear/> 
          </Card>


        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Purchase Amount Prediction (Next 12 Months)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <PurchasePredAmountNext12M/>
        </Card>
      </div>

      <div className='d-flex w-100'>
        <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Purchase Count Prediction (Until the End of Year)")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <PurchasePredCountEndofYear/> 
          </Card>


        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
              {t("Purchase Count Prediction (Next 12 Months)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <PurchasePredCountNext12M/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Purchase Amount Prediction (Weekly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <PurchasePredAmountWeekly hvalue="250%"/>
        </Card>
      </div>

      <div className='d-flex'>

      <Card className="pages-container">
        <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
          <Typography variant="caption" className="bar-title" gutterBottom>
          {t("Purchase Amount Prediction (Monthly)")}
          </Typography>
          <div className={{ flexGrow: 1 }} />
        </div>
        <PurchasePredAmountMonthly hvalue="250%"/>
      </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Purchase Count Prediction (Monthly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <PurchasePredCountMonthly hvalue="250%"/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Purchase Count Prediction (Weekly)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <PurchasePredCountWeekly hvalue="250%" />
        </Card>
      </div>

      { packageType === "premium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Purchase Seasonality Trend Anomaly")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <PurchaseSeasonalityTrendAnomaly hvalue="250%"/>
          </Card>
        </div> 
      }

      {packageType === "freemium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
              {t("Purchase Seasonality Trend Anomaly")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>

            <div className="blur-container"> 
              <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
              <a type='button' className="btn" href='/packages'>{t(msg)}</a>
            </div>

          </Card>
        </div>
      
      }
      
    </>
  );
}
