import React, { useState, useEffect } from 'react';

import ReactEcharts from "echarts-for-react"; 
import * as echarts from 'echarts/core';

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';

import { ScatterChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import { useTheme } from '@material-ui/core/styles';

export default function Top3ExpendBySegmentTableLast({ hvalue="300%" }) {

  const [dataArr, setDataArr] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const firmid = window.sessionStorage.getItem("comp_id");
  const integration = window.sessionStorage.getItem("integration");

  const themeS = useTheme();
  const themeText = themeS.palette.text.primary;

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    ScatterChart,
    CanvasRenderer,
    UniversalTransition
  ]);

  var segments_dict = {
    'Hibernate': 1, 
    'Risk':2, 
    'Cant Lose':3,
    'Sleeper':4,
    'Need Attention':5,
    'Loyal':6,
    'Promising':7,
    'New Customer':8,
    'High Potential':9,
    'Champion':10
  }

  useEffect(() => {

    // const ac = new AbortController();

    if(integration === "1" || firmid === null || firmid === undefined) {
      var list_obj = null
    }
    else {
      var list_obj = {
        "path": "./files/"+firmid+"/gider/"
      }
    }

    fetch(process.env.REACT_APP_REQ_IP + '/top3_expend_by_segment_lastmonth_table', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
      
      if(data.results) {

        const urun_uniq_dict = {};
        const tmp_arr = [];

        const urun_dict = []

        const values = JSON.parse(data.df);

        const unique = [...new Set(values.map(item => item.ProdName))];
        const unique_segment = [...new Set(values.map(item => t(item.Segment)))];

        unique.forEach((urun, index) => {
          urun_uniq_dict[urun] = index;
        })

        values.map(el => {
          tmp_arr.push([segments_dict[el.Segment], urun_uniq_dict[el.ProdName], el.Power]);

          var shortTitle = el.ProdName.length > 15 ? el.ProdName.substring(0,15) + "..." : el.ProdName;
          urun_dict.push(shortTitle);
        })

        const option = {
          textStyle: {
            color : themeText
          },
          tooltip: {
            show: true,
            trigger: "item",
            position: 'top',
            formatter: function (params) {
              return (
                t("Name : ") + params.name
              );
            }
          },
          grid: {
            containLabel: true,
            height: "80%",
          },
          visualMap: [
            {
              type: 'piecewise',
              calculable: true,
              show: false,
              dimension: 1,
              pieces: [
                {
                  gt: 1,
                  lte: 2,
                  color: '#003280'
                },
                {
                  gt: 2,
                  lte: 3,
                  color: '#67a3ff'
                },
                {
                  gt: 3,
                  lte: 4,
                  color: '#d83534'
                },
                {
                  gt: 4,
                  lte: 5,
                  color: '#33cd32'
                },
                {
                  gt: 5,
                  lte: 6,
                  color: '#ff7e0e'
                },
                {
                  gt: 6,
                  lte: 7,
                  color: '#294166'
                },
                {
                  gt: 7,
                  lte: 8,
                  color: '#0064ff'
                },
                {
                  gt: 8,
                  lte: 9,
                  color: '#6c1b1a'
                },
                {
                  gt: 9,
                  lte: 10,
                  color: '#6c1b1a'
                }
              ],
              outOfRange: {
                color: '#249023'
              },
            }
          ],
          xAxis: {
            type: 'category',
            data: urun_dict,
            boundaryGap: false,
            splitLine: {
              show: true
            },
            axisLine: {
              show: false,
              // lineStyle: 
            },
            axisLabel: { 
              interval: 0, 
              rotate: 45,
            }
          },
          yAxis: {
            type: 'category',
            data: unique_segment,
            boundaryGap: false,
            splitLine: {
              show: true
            },
            axisLine: {
              show: false
            }
          },
          series: [
            {
              type: 'scatter',
              symbolSize: function (val) {
                if(val[2] > 100) {
                  return val[2] / 10;
                }
                else if(val[2] < 100 && val[2] > 50) {
                  return val[2];
                }
                else {
                  return val[2] * 2;
                }
              },
              data: tmp_arr,
              animationDelay: function (idx) {
                return idx * 5;
              }
            }
          ]
        };

        setDataArr(option);


      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
        console.log(err);
    });

  }, [firmid, integration, t, themeText]);
  

  return (
    <>
      {dataArr === null && serverMsg !== null && 
        <div className="blur-container"> 
          <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
          <span>{t(serverMsg)}</span>
        </div>
      }
      {dataArr !== null && serverMsg === null &&
        <ReactEcharts option={dataArr} /> 
      }
    </>

  );
}