import {Adapter, StreamingAdapterObserver} from '@nlux/react';

export const streamAdapter: Adapter = {
  
  streamText: async (prompt: string, observer: StreamingAdapterObserver) => {
    const firmid = window.sessionStorage.getItem("comp_id");
    const uid = window.sessionStorage.getItem("uid");

    const body = { prompt, firmid, uid};

    const response = await fetch(process.env.REACT_APP_REQ_IP + '/chatgbt_integration', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (response.status !== 200) {
      observer.error(new Error('Failed to connect to the server'));
      return;
    }

    if (!response.body) {
      return;
    }

    const reader = response.body.getReader();
    const textDecoder = new TextDecoder();

    let doneReading = false;

    while (!doneReading) {
      const { value, done } = await reader.read();

      if (done) {
        doneReading = true;
        continue;
      }

      const content = textDecoder.decode(value);

      if (content) {
        observer.next(content);
      }
    }

    observer.complete();
  }
};