import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: theme.spacing(2)
  }
}));

const widgetNames = {
  "1": 'Sales Amount Prediction (Until the End of Year)',
  "2": "Sales Seasonality Trend Anomaly",
  "3": "Sales Amount Prediction (Monthly)",
  "4": "Sales Amount Prediction (Weekly)",
  "5": "Sales Count Prediction (Monthly)",
  "6": "Sales Count Prediction (Weekly)",
  "7": "Sales Amount Prediction (Next 12 Months)",
  "8": "Sales Count Prediction (Next 12 Months)",
  "9": "Sales Count Prediction (Until the End of Year)",
  "10": "Sales Forecast Factor Analysis by Time",

  "11": 'Purchase Amount Prediction (Until the End of Year)',
  "12": "Purchase Seasonality Trend Anomaly",
  "13": "Purchase Amount Prediction (Monthly)",
  "14": "Purchase Amount Prediction (Weekly)",
  "15": "Purchase Count Prediction (Monthly)",
  "16": "Purchase Count Prediction (Weekly)",
  "17": "Purchase Amount Prediction (Next 12 Months)",
  "18": "Purchase Count Prediction (Next 12 Months)",
  "19": "Purchase Count Prediction (Until the End of Year)",
  "20": "Purchase Forecast Factor Analysis by Time",

  "21": 'Profit Amount Prediction (Until the End of Year)',
  "22": "Profit Seasonality Trend Anomaly",
  "23": "Profit Amount Prediction (Monthly)",
  "24": "Profit Amount Prediction (Weekly)",
  "25": "Profit Count Prediction (Monthly)",
  "26": "Profit Count Prediction (Weekly)",
  "27": "Profit Amount Prediction (Next 12 Months)",
  "28": "Profit Count Prediction (Next 12 Months)",
  "29": "Profit Count Prediction (Until the End of Year)",
  "30": "Profit Forecast Factor Analysis by Time",

  "31": 'Expend Segmentation Analysis',
  "32": "Customer Segmentation Analysis",
  "33": "Product Segmentation Analysis",
  "34": "Supplier Segmentation Analysis",

};

export default function AddList({ items, onRemoveItem, onAddItem, originalItems }) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const { t, i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e) => {
    if (e.target.checked) {
      onAddItem(e.target.name);
    } else {
      onRemoveItem(e.target.name);
    }
  };

  return (
    <>
      <IconButton aria-label="add" onClick={handleClick} aria-describedby={id}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}>
        <div className={classes.popup}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("Select analysis")}</FormLabel>
            <FormGroup>
              {originalItems.map((i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={items.includes(i)}
                      onChange={handleChange}
                      name={i}
                    />
                  }
                  label={t(widgetNames[i])}
                  key={i}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </Popover>
    </>
  );
}
