import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate } from "react-router-dom";

import * as Components from "../assets/js/auth";

import LangSwitch from '../components/LangSwitch';
import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const bcrypt = require('react-native-bcrypt');

function ResetPass() {

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //reset password event
    const [passError, setPassError] = useState('');

    const { t, i18n } = useTranslation();

    let navigate = useNavigate();

    function handleResetPassword() { 
        setIsLoading(true);

        const email = document.getElementById('email').value;
        const passw = document.getElementById('passw').value;
        const passw_conf = document.getElementById('passw_conf').value;

        var salt = bcrypt.genSaltSync(10);
        var hashedPassw = bcrypt.hashSync(passw, salt);

        var tempCompare = bcrypt.compareSync(passw_conf, hashedPassw); 

        if(tempCompare === true) {

            if(passw.length>=6 && passw_conf.length>=6) {

                const list_obj = {
                    'email': email,
                    'passw': hashedPassw,
                    "lang": i18n.language
                }

                fetch(process.env.REACT_APP_REQ_IP + '/reset', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
                    
                    setIsLoading(false);

                    if(data.result === true) {
                        enqueueSnackbar(t(data.message), { 
                            variant: 'success',
                            persist: false
                        });
                        navigate("/login", { replace: true });
                    }
                    else {
                        enqueueSnackbar(t(data.message), { 
                            variant: 'error',
                            persist: false
                        });
                    }

                })
                .catch(err => {
                    setIsLoading(false);
                    setError(err);
                });
            }
            else {
                setIsLoading(false);
                setPassError('Your password must be at least 6 characters!');
            }
        }
        else {
            setIsLoading(false);
            setPassError('The passwords you entered do not match, please check!');
        }
    }


    if (isLoading) {
        return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
          </View>
        );
    }
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
          <div className='login-body'>  
            <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
         
            <Components.Container>

            <LangSwitch />
              <form className='login-form reset-pass-form' method="POST" onSubmit={handleResetPassword}>
                <Components.Title className='mb-3 align-self-center'>{t("Reset Password")}</Components.Title>

                <Components.Input type="email" placeholder={t("Email")} className="form-control" id="email" required />

                <span style={{ color: 'red', fontSize: '0.7em' }}>{t(passError)}</span>
                <Components.Input type="password" placeholder={t("Password")} className="form-control" id="passw" required/>
                <Components.Input type="password" placeholder={t("Password Confirmation")} className="form-control" id="passw_conf" required/>
                <Components.Anchor className='align-self-center' href="/login">{t("Back to Login")}</Components.Anchor>
                <Components.Ghost2Button className='align-self-center' type="submit">{t("Reset")}</Components.Ghost2Button>
              </form>
          </Components.Container>
          </div>
    )}
}

export default ResetPass;