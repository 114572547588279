import React from 'react';
import { Image } from 'react-native';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import RFMQuartileExpend from '../../charts/rfm_quartile_expend';
import Top20ExpendAmount from '../../charts/top20_expend_amount';
import Top20ExpendCount from '../../charts/top20_expend_count';
import ExpendSegmentation3D from '../../charts/3d_expend_segmentation';

import SeasonalityImg from "../../assets/img/season.png";

export default function ExpendAnalytics(packageType, msg="") {

  const { t, i18n } = useTranslation();

  return (   
    <> 

      { packageType === "premium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" gutterBottom>
                {t("Expend Segmentation Analysis")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>
            <RFMQuartileExpend hvalue="250%"/>
          </Card>
        </div> 
      }

      {packageType === "freemium" &&
        <div>
          <Card className="pages-container">
            <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
              <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
              {t("Expend Segmentation Analysis")}
              </Typography>
              <div className={{ flexGrow: 1 }} />
            </div>

            <div className="blur-container"> 
              <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
              <a type='button' className="btn" href='/packages'>{t(msg)}</a>
            </div>

          </Card>
        </div>
      }

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Top 20 Expend (Amount)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <Top20ExpendAmount hvalue="150%"/>
        </Card>
      </div>

      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("Top 20 Expend (Count)")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <Top20ExpendCount hvalue="150%"/>
        </Card>
      </div>
      
      <div className='d-flex'>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" gutterBottom>
            {t("3D Product Segmentation Analysis")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>
          <ExpendSegmentation3D hvalue="150%"/>
        </Card>
      </div>

    </>
  );
}
