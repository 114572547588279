import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import LanguageIcon from '@material-ui/icons/Language';

import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function LangSwitch() {

  const [anchorEl, setAnchorEl] = useState(null);
  const [langLabel, setLangLabel] = useState("TR");

  const { t, i18n } = useTranslation();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function changeLanguageHandler (e) {

    const languageValue = e.currentTarget.outerText;

    setLangLabel(languageValue);

    i18n.changeLanguage(languageValue.toLowerCase());
    
  }

  return (
        <>
          <div className="lang-label-container">
            <IconButton
                color="inherit"
                edge="start"
                onClick={handleClick}
                aria-controls={open ? 'lang-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
              <LanguageIcon />
            </IconButton>
            {langLabel}
          </div>

          <Menu
            anchorEl={anchorEl}      
            id="lang-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}>

            <MenuItem onClick={changeLanguageHandler}>
              <img src="tr.png" alt="logo" className="flag-icon" />
              TR
            </MenuItem>
            <MenuItem onClick={changeLanguageHandler}>
              <img src="uk.png" alt="logo" className="flag-icon" />
              EN
            </MenuItem>
          </Menu>
        </>
  );
}
