import {React} from 'react';
import { Image } from 'react-native';

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import SeasonalityImg from "../../assets/img/season.png";
import ChatBot from '../ChatBot';

export default function ChatBotPage(packageType, msg="") {
  const { t, i18n } = useTranslation();

  return (   
    <> 
    {/* {packageType === "premium" &&
      <div className='d-flex'>
        <ChatBot/>
      </div>
    } */}

    {/* {packageType === "freemium" && */}
      <div>
        <Card className="pages-container">
          <div className={{ display: "flex", alignItems: "center", padding: "0.5rem"}}>
            <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
            {t("ChatGBT'ye Sor")}
            </Typography>
            <div className={{ flexGrow: 1 }} />
          </div>

          <div className="blur-container"> 
            <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"4px" }} resizeMethod='scale' blurRadius={2}/>
            <a type='button' className="btn" href='/packages'>{t("This module is an exclusive package. Please consult your sales representative.")}</a>
          </div>

        </Card>
      </div>
    {/* } */}
    </>
  );
}
