import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Sign from './pages/Sign';
import FirstLogin from './pages/FirstLogin';
import ResetPass from './pages/ResetPass';
import Missing from './pages/Missing';
import Unauthorized from './pages/Unauthorized';
import Packages from './pages/Packages';
import Success from './pages/Success';
import Failure from './pages/Failure';
import Authentication from './pages/Authentication';

import Theme from './Theme';
import ThemeFreemium from './ThemeFreemium';

// import "/node_modules/react-grid-layout/css/styles.css"
// import "/node_modules/react-resizable/css/styles.css"

class App extends React.Component {	
    constructor(props){
      super(props);
    
      this.state = {};
    }
    
    render(){
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Sign />}/> 
            <Route path="/login" element={<Sign />}/> 
            <Route path="/reset" element={<ResetPass />}/> 
            <Route path="/packages" element={<Packages />}/> 
            <Route path='/choice' element = {<FirstLogin/>}/>
            <Route path='/sandbox' element = {<ThemeFreemium/>}/>
            <Route path='/app' element = {<Theme/>}/>
            <Route path="/auth/unverified" element={<Authentication/>}/> 
            <Route path='/unauthorized' element={<Unauthorized/>}/>
            <Route path="/payment/success" element={<Success />}/> 
            <Route path="/payment/failure" element={<Failure />}/> 
            <Route path="*" element={<Missing />} />
          </Routes>
        </BrowserRouter>   
      )
    }
}

render(<App></App>, document.querySelector('#root'));

export default App;

